import React from 'react';
import styled from 'styled-components';

// Assets
import HeroMock from 'assets/heroImg-min.jpg';
import Line from 'assets/line2.svg';
import HeroDecorLeft from 'assets/hero-d-left-min.png';
import HeroDecorRight from 'assets/hero-d-right-min.png';

// Icons (Refactor with Iconmoon fonts)
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Style
import './Hero.scss';

const Hero = () => (
    <div>
        <div id="Hero" className="jumbotron jumbotron-fluid">
            <div className="container">
                <h1 className="display-4">
                    <strong>
                        Discover a New World
                        <br /> of{' '}
                        <strong>
                            Air Force <img id="line-marker" src={Line} alt="" className="img-fluid" />
                        </strong>{' '}
                        Education.
                    </strong>
                </h1>
                <p className="mt-5">
                    The <strong>AI-powered</strong> Air Force Educational Tool.
                </p>
                <div className="hero-btns mt-5">
                    <a href="#" className="btn btn-md btn-primary-hero">
                        See it in Action <img src={ArrowWhiteIcon} alt="Icon Arrow" className="i-arrow img-fluid" />
                    </a>
                    <a href="#" className="btn btn-md btn-secondary">
                        Contact
                    </a>
                </div>
            </div>
            {/* SVG Decors */}
            <img src={HeroDecorLeft} alt="Hero Decor SVG" className="hero-d-left img-fluid" />
            <img src={HeroDecorRight} alt="Hero Decor SVG" className="hero-d-right img-fluid" />
        </div>
        <div id="hero-mockup" className="container extended">
            <div className="mt-3">
                <img src={HeroMock} alt="" className="img-fluid" />
            </div>
        </div>
    </div>
);

export default Hero;
