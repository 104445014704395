import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

// Assets
// import Logo from 'assets/geLogo.png'
import Logo from 'assets/learneckeLogo.png';

// Components
import { Navigation, Footer } from 'components';

// Styles
import './Signup.scss';

// API
import apiHeaders from 'services/apiHeader';
import apiurls from 'services/apiRoutes';

const Signup = () => {
    const newUser = {
        email: '',
        // username: "",
        password: '',
        full_name: '',
    };
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    // const [username, setUsername] = useState("");
    const [password, setPassword] = useState('');
    const [fullname, setFullname] = useState('');
    const [alert, setAlert] = useState('Sign up');
    const [userSuccess, setUserSuccess] = useState(false);

    useEffect(() => {
        getUsers();
        // Clean Up
        return () => {
            setUsers([]);
        };
    }, []);

    const getUsers = async () => {
        const res = await axios.get(apiurls.users, { headers: apiHeaders });
        // console.log(res.data);
        setUsers(res.data);
    };

    // Handlers
    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    };
    // const usernameChangeHandler = (event) => {
    //     setUsername(event.target.value);
    // }
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };
    const fullnameChangeHandler = (event) => {
        setFullname(event.target.value);
    };

    // Post Data
    const createUser = async () => {
        let flag = true;

        for (const i in users) {
            if (email === users[i].email) {
                // || username === users[i].username

                // console.log("Email address already exists!");
                // console.log("Failed Creating User Account. Try Again.");
                flag = false;
            } else {
                setAlert('Verifying...');
                newUser.email = email;
                // newUser.username = username;
                newUser.password = password;
                newUser.full_name = fullname;
            }
        }

        if (
            flag &&
            newUser.email !== '' &&
            newUser.password !== '' &&
            (newUser.password === '2M0X3' || newUser.password === 'CVOT')
        ) {
            // && newUser.username != ""
            const data = await axios.post(apiurls.users, newUser, { headers: apiHeaders });
            // console.log(data);
            setTimeout(() => {
                setAlert('Success');
            }, 1000);
            setTimeout(() => {
                setUserSuccess(true);
            }, 2000);
        } else {
            setTimeout(() => {
                setAlert('Invalid Signup. Try Again.');
                setEmail('');
                setPassword('');
            }, 1000);
        }

        newUser.email = '';
        // newUser.username = "";
        newUser.password = '';
        newUser.full_name = '';
        getUsers();
    };

    useEffect(
        () => () => {
            createUser();
        },
        []
    );

    return (
        <div id="Signup">
            {userSuccess ? <Redirect to="/login" /> : null}
            <Navigation />
            <div className="jumbotron-wrapper">
                <div className="jumbotron">
                    <form>
                        <div className="text-center">
                            <img src={Logo} alt="" className="img-fluid" />
                            {/* <h5 className="text-center mt-3">Sign Up to Growth Engine AI</h5> */}
                        </div>
                        <div className="form-group mt-5">
                            <input
                                onChange={fullnameChangeHandler}
                                value={fullname}
                                type="text"
                                className="form-control"
                                id="FullnameInput"
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="form-group mt-4">
                            <input
                                onChange={emailChangeHandler}
                                value={email}
                                type="email"
                                className="form-control"
                                id="EmailInput"
                                aria-describedby="emailHelp"
                                placeholder="Email Address"
                            />
                        </div>
                        {/* <div className="form-group mt-4">
                            <input
                                onChange={usernameChangeHandler}
                                value={username}
                                type="text" className="form-control" id="UsernameInput" placeholder="Username" />
                        </div> */}
                        <div className="form-group mt-4">
                            <input
                                onChange={passwordChangeHandler}
                                value={password}
                                type="password"
                                className="form-control"
                                id="PasswordInput"
                                placeholder="•••••••••••"
                            />
                        </div>
                        <button onClick={createUser} type="button" className="btn btn-primary mt-4 w-100">
                            {alert}
                        </button>
                        <p className="text-center mt-5">
                            Have an account? <Link to="/login">Login</Link>
                        </p>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Signup;
