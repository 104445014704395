import React from 'react';

import { useLocation } from 'react-router-dom';

// Components
import QA from './QuizComponents/QA';

const Quiz = ({ props }) => {
    const location = useLocation();
    const { articleName, lessonId } = location.state;

    console.log('ARTICLE NAME: ', articleName);
    console.log('LESSON ID: ', lessonId);
    return (
        <>
            <div id="quiz" className="quiz mt-5">
                <QA articleName={articleName} />
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default Quiz;
