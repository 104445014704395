import React from 'react';

import './Management.scss';

const SummaryArea = (props) => {
    const { title, total, average, correct, incorrect, percent, pointsAvgPerQuestion, pointsAvgPerQuiz } = props;
    return (
        <div className="summary-area-wrapper">
            <div className="summary-title">{title}</div>
            <ul className="summary-area-list">
                {typeof pointsAvgPerQuiz !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{pointsAvgPerQuiz}</span> average per quiz
                    </li>
                ) : (
                    ''
                )}
                {typeof pointsAvgPerQuestion !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{pointsAvgPerQuestion}</span> average per question
                    </li>
                ) : (
                    ''
                )}
                {typeof total !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{total}</span> total
                    </li>
                ) : (
                    ''
                )}
                {typeof average !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{average}</span> average
                    </li>
                ) : (
                    ''
                )}
                {typeof correct !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{correct}</span> corrects
                    </li>
                ) : (
                    ''
                )}
                {typeof incorrect !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{incorrect}</span> incorrects
                    </li>
                ) : (
                    ''
                )}
                {typeof percent !== 'undefined' ? (
                    <li style={{ listStyle: 'none' }}>
                        <span className="summary-value">{percent * 100}%</span> correct
                    </li>
                ) : (
                    ''
                )}
            </ul>
        </div>
    );
};

export default SummaryArea;
