import React, { useContext, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';

// Assets
import Logo from 'assets/learneckeLogo.png';

// Components
import { Navigation, Footer } from 'components';
import { GlobalContext } from 'stores/globalStore';

// Styles
import './Login.scss';

// APIs
import authService from 'services/auth.service';
import userService from 'services/user.service';

// Utils
import { generateSessionId } from 'utils/generateSessionId';

const Login = () => {
    const { store, dispatch } = useContext(GlobalContext)
    const [isLogged, setIsLogged] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState('Login');
    const [isCheckingLogin, setIsCheckingLogin] = useState(false);

    // Handlers
    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    };
    const passwordChangeHandler = (event) => {
        setPassword(event.target.value);
    };

    const setUserInfo = (fullName, avatar) => {
        dispatch({
            type: 'updateUserInfo',
            userInfo: {
                fullName,
                avatar,
            }
        })
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        localStorage.clear();
        setIsCheckingLogin(true);
        setAlert('Verifying...');
        await authService.login(email, password).then(
            (response) => {
                if (response.status === 200) {
                    userService.getUserMe().then((response) => {
                        setTimeout(() => {
                            localStorage.setItem('full_name', response.full_name);
                            localStorage.setItem('lms_role', response.lms_role);

                            localStorage.setItem('email', response.email);
                            localStorage.setItem('isLogged', true);
                            localStorage.setItem('user_id', response.id);
                            localStorage.setItem('sess_id', generateSessionId());
                            setUserInfo(response.full_name, response.image_url);
                            setIsLogged(true);
                        }, 1000);

                        setAlert('Success!');
                        setIsCheckingLogin(false);
                    });
                } else {
                    setEmail('');
                    setPassword('');
                    setAlert('Invalid Login. Try again.');
                    setIsCheckingLogin(false);
                }
            },
            (error) => {
                setEmail('');
                setPassword('');
                setAlert('Invalid Login. Try again.');
                setIsCheckingLogin(false);
            }
        );
    };

    return (
        <div id="Login">
            {isLogged ? <Redirect to="/home" /> : null}
            <Navigation />
            <div className="jumbotron-wrapper">
                <div className="jumbotron">
                    <form>
                        <div className="text-center">
                            <img src={Logo} alt="" className="img-fluid" />
                        </div>
                        <div className="form-group mt-5">
                            <input
                                onChange={emailChangeHandler}
                                disabled={isCheckingLogin}
                                value={email}
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="jane@example.com"
                                required
                            />
                        </div>
                        <div className="form-group mt-4">
                            <input
                                onChange={passwordChangeHandler}
                                disabled={isCheckingLogin}
                                value={password}
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="•••••••••••"
                                required
                            />
                        </div>
                        <button
                            onClick={handleLogin}
                            type="submit"
                            className="btn btn-primary mt-4 w-100"
                            disabled={isCheckingLogin}
                        >
                            {alert}
                        </button>
                        <p className="text-center mt-5">
                            Don't have an account? <Link to="/signup">Sign up</Link>
                        </p>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default withRouter(Login);
