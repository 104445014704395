// Users API
// const $getUsers = 'https://af-fake-server.herokuapp.com/users';
// const $getUsers = 'https://lms.api.learnecke.com/users/';

// console.log(users[i])
// users[i] = JSON.parse(users[i])
// console.log(users[i])
// console.log(users[i]["email"])

// let $getPrograms = `https://af-fake-server.herokuapp.com/LMS`;
// let $getPrograms = `https://lms.api.learnecke.com/curriculums/`;

// Users API
// const $getUsers = 'https://af-fake-server.herokuapp.com/users';
// const $postUser = 'https://af-fake-server.herokuapp.com/users';
// const $getUsers = 'https://lms.api.learnecke.com/users/';
// const $postUser = 'https://lms.api.learnecke.com/users/';

// * DEV
// const apiurls = {
//     'users': 'http://localhost:3000/users/',
//     'curriculums': 'http://localhost:3000/curriculums/',
//     // 'quizzes': 'http://localhost:3000/quizzes/',
//     'Q2M0X3': 'http://localhost:3000/Q2M0X3/',
//     'QCVOT': 'http://localhost:3000/QCVOT/',
//     'grades': 'http://localhost:3000/grades/'
// }

// PROD Heroku
const apiurls = {
    users: process.env.REACT_APP_API_USER,
    curriculums: process.env.REACT_APP_API_CURRICULUM,
    Q2M0X3: process.env.REACT_APP_API_Q2M0X3,
    QCVOT: process.env.REACT_APP_API_QCVOT,
    grades: process.env.REACT_APP_API_GRADE,
    analysisStudents: process.env.REACT_APP_API_ANALYSIS_STUDENTS,
    learnerQuestionsData: process.env.REACT_APP_API_LEARNER_QUESTIONS_DATA,
    weaknessAndStrength: process.env.REACT_APP_API_WEAKNESS_STRENGTH,
    analysisUnit: process.env.REACT_APP_API_ANALYSIS_UNIT,
    unitWeaknessAndStrength: process.env.REACT_APP_API_UNIT_WEAKNESS_STRENGTH,
    getQuestions: process.env.REACT_APP_API_GET_QUESTIONS,
    generateQuestions: process.env.REACT_APP_API_GENERATE_QUESTIONS,
    saveQuestions: process.env.REACT_APP_API_SAVE_QUESTIONS,
    deleteQuestions: process.env.REACT_APP_API_DELETE_QUESTIONS,
    updateQuestions: process.env.REACT_APP_API_UPDATE_QUESTIONS,
};

// * PROD Learnecke
// const apiurls = {
//     'users': 'https://lms.api.learnecke.com/users/',
//     'login': 'https://lms.api.learnecke.com/users/validate/',
//     'curriculums': 'https://lms.api.learnecke.com/curriculums/',
//     'quizzes': 'https://lms.api.learnecke.com/quizzes/',
// }

// const apiurls = JSON.parse(data)

export default apiurls;
