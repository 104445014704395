import React, { useState, useEffect, useRef, useContext } from 'react';

// Components
import { FaSortAmountDownAlt, FaSortAmountUp } from 'react-icons/fa';
import { SiGoogleanalytics } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'stores/globalStore';
import SummaryArea from '../../SummaryArea';
import { slideDown, slideUp } from './anim';
import './CollapseTable.scss';
import '../../Management.scss';

const SubSection = ({ title, value }) => (
    <div className="summary-area-wrapper">
        <div className="summary-title">{title}</div>
        <ul className="summary-area-list">
            <li style={{ listStyle: 'none' }}>
                <span className="summary-value">{value}</span>
            </li>
        </ul>
    </div>
);

class UserTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false };
    }

    toggleExpander = (e) => {
        if (e.target.type === 'checkbox') return;

        if (!this.state.expanded) {
            this.setState({ expanded: true }, () => {
                if (this.refs.expanderBody) {
                    slideDown(this.refs.expanderBody);
                }
            });
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {
                    this.setState({ expanded: false });
                },
            });
        }
    };

    render() {
        return [
            <tr key="main" onClick={this.toggleExpander} className="collapse-row">
                <td>
                    <input
                        id={this.props.index}
                        className="uk-checkbox"
                        type="checkbox"
                        checked={this.props.user.check}
                        onChange={this.props.handleCheckbox}
                    />
                </td>
                <td>{this.props.user.name}</td>
                <td>{this.props.user.unit}</td>
                <td>{this.props.user.rank}</td>
            </tr>,
            this.state.expanded && (
                <tr className="expandable" key="tr-expander">
                    <td className="uk-background-muted" colSpan={6}>
                        <div ref="expanderBody" className="inner uk-grid">
                            <p className="expander-child-cell">
                                <span className="expander-child-label">Email address</span>
                                <span className="expander-child-content">{this.props.user.email}</span>
                            </p>
                            <div className="divider" />
                            <div className="summary-section">
                                <div className="summary-subsection-wrapper">
                                    <div className="summary-subsection">
                                        <SubSection
                                            title="Quiz attempts completed"
                                            value={this.props.user.quiz_attempts_completed}
                                        />
                                        <SubSection
                                            title="Courses completed"
                                            value={this.props.user.courses_completed}
                                        />
                                    </div>
                                    <div className="summary-subsection">
                                        <SubSection
                                            title="Question Attempts"
                                            value={this.props.user.question_attempts}
                                        />
                                    </div>
                                    <div className="summary-subsection">
                                        <div className="summary-area-wrapper">
                                            <div className="summary-title">Time</div>
                                            <ul className="summary-area-list">
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.time_per_course}
                                                    </span>{' '}
                                                    per course
                                                </li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.time_per_quiz}
                                                    </span>{' '}
                                                    per quiz
                                                </li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.time_per_question}
                                                    </span>{' '}
                                                    per question
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="summary-area-wrapper">
                                            <div className="summary-title">Points</div>
                                            <ul className="summary-area-list">
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.points_per_quiz}
                                                    </span>{' '}
                                                    per quiz
                                                </li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.points_per_question}
                                                    </span>{' '}
                                                    per question
                                                </li>
                                                <li style={{ listStyle: 'none' }}>
                                                    <span className="summary-value">
                                                        {this.props.user.total_points}
                                                    </span>{' '}
                                                    total
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider-line" />
                                <div className="summary-subsection-wrapper-2">
                                    <div className="summary-area-wrapper">
                                        <div className="summary-title">Questions Answered</div>
                                        <ul className="summary-area-list">
                                            <li style={{ listStyle: 'none' }}>
                                                <span className="summary-value">
                                                    {this.props.user.questions_answered}
                                                </span>{' '}
                                                total
                                            </li>
                                            <li style={{ listStyle: 'none' }}>
                                                <span className="summary-value">
                                                    {this.props.user.questions_answered_correct}
                                                </span>{' '}
                                                corrects
                                            </li>
                                            <li style={{ listStyle: 'none' }}>
                                                <span className="summary-value">
                                                    {this.props.user.questions_answered -
                                                        this.props.user.questions_answered_correct}
                                                </span>{' '}
                                                incorrects
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="expander-analytics-link">
                                <Link
                                    to={`/analytics/learners/${this.props.user.userId}?start=${this.props.dayRange[0]}&end=${this.props.dayRange[1]}`}
                                >
                                    <SiGoogleanalytics />
                                    <span>Analytics</span>
                                </Link>
                            </div>
                        </div>
                    </td>
                </tr>
            ),
        ];
    }
}

const useSortableData = (items, config = { key: 'name', direction: 'descending' }) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        const sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};

const CollapseTable = ({ dayRange }) => {
    const { store, dispatch } = useContext(GlobalContext);
    const { users } = store;

    const { items, requestSort, sortConfig } = useSortableData(users);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const isLoading = false;
    const [totalCheckValue, setTotalCheckValue] = useState(false);

    const handleTotalCheckbox = (e) => {
        setTotalCheckValue(e.target.checked);
        setUsers(
            users.map((element) => ({
                ...element,
                check: e.target.checked,
            }))
        );
    };

    const handleCheckbox = (e) => {
        setUsers(
            users.map((element) => ({
                ...element,
                check: String(element.id) === String(e.target.id) ? e.target.checked : element.check,
            }))
        );
    };

    const setUsers = (param) => {
        dispatch({
            type: 'updateUsers',
            users: param,
        });
    };

    useEffect(() => {
        if (users.filter((user) => user.check === false).length === 0) {
            setTotalCheckValue(true);
        } else setTotalCheckValue(false);
    }, [users]);

    return (
        <table className="collapsible-table uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead className="uk-table-header">
                <tr>
                    <th
                        className="uk-table-shrink"
                        onClick={() => requestSort('check')}
                        className={getClassNamesFor('check')}
                    >
                        <input
                            className="uk-checkbox"
                            type="checkbox"
                            checked={totalCheckValue}
                            onChange={handleTotalCheckbox}
                        />
                        {sortConfig.key === 'check' && (
                            <div className="uk-table-icon">
                                {sortConfig.direction === 'descending' ? <FaSortAmountUp /> : <FaSortAmountDownAlt />}
                            </div>
                        )}
                    </th>
                    <th
                        className="uk-table-shrink uk-table-cell"
                        onClick={() => requestSort('name')}
                        className={getClassNamesFor('name')}
                    >
                        <div className="uk-table-cell">
                            <span>Name</span>
                            {sortConfig.key === 'name' && (
                                <div className="uk-table-icon">
                                    {sortConfig.direction === 'descending' ? (
                                        <FaSortAmountUp />
                                    ) : (
                                        <FaSortAmountDownAlt />
                                    )}
                                </div>
                            )}
                        </div>
                    </th>
                    <th
                        className="uk-table-shrink uk-table-cell"
                        onClick={() => requestSort('unit')}
                        className={getClassNamesFor('unit')}
                    >
                        <div className="uk-table-cell">
                            <span>Unit</span>
                            {sortConfig.key === 'unit' && (
                                <div className="uk-table-icon">
                                    {sortConfig.direction === 'descending' ? (
                                        <FaSortAmountUp />
                                    ) : (
                                        <FaSortAmountDownAlt />
                                    )}
                                </div>
                            )}
                        </div>
                    </th>
                    <th
                        className="uk-table-shrink uk-table-cell"
                        onClick={() => requestSort('rank')}
                        className={getClassNamesFor('rank')}
                    >
                        <div className="uk-table-cell">
                            <span>Rank</span>
                            {sortConfig.key === 'rank' && (
                                <div className="uk-table-icon">
                                    {sortConfig.direction === 'descending' ? (
                                        <FaSortAmountUp />
                                    ) : (
                                        <FaSortAmountDownAlt />
                                    )}
                                </div>
                            )}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {isLoading ? (
                    <tr>
                        <td colSpan={6} className="uk-text-center">
                            <em className="uk-text-muted">Loading...</em>
                        </td>
                    </tr>
                ) : (
                    items.map((user) => (
                        <UserTableRow
                            key={user.id}
                            index={user.id}
                            dayRange={dayRange}
                            user={user}
                            handleCheckbox={handleCheckbox}
                        />
                    ))
                )}
            </tbody>
        </table>
    );
};

export default CollapseTable;
