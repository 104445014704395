import React, { useState, useEffect, useRef, useContext } from 'react';

// Components
import { Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// APIs
import userService from 'services/user.service';

// Assets
import UploadIcon from 'assets/Icons/upload.svg';

// Styles
import './MyAccount.scss';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from 'stores/globalStore';

const MyAccount = () => {
    // My Account Handler
    const { store, dispatch } = useContext(GlobalContext);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [role, setRole] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [avatar, setAvatar] = useState(null);

    useEffect(async () => {
        setMounted(false);
        userService.getUserMe().then(response => {
            setAvatar(response.image_url);
            setFullName(response.full_name ? response.full_name : '');
            setEmail(response.email);
            setRole(response.lms_role);
            setMounted(true);
        });
    }, []);
    // End of My Account Handler

    const inputRef = useRef();

    function emailChangeHandler(event) {
        setEmail(event.target.value);
    }

    function fullNameChangeHandler(event) {
        setFullName(event.target.value);
    }

    const setUserInfo = (fullName, avatar) => {
        dispatch({
            type: 'updateUserInfo',
            userInfo: {
                fullName,
                avatar
            }
        });
    };

    // End of Event Handler

    const handleChangeAvatar = async event => {
        event.persist();

        const reader = new FileReader();
        let imageStringData = '';

        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        reader.onloadend = () => {
            imageStringData = reader.result;
        };

        reader.readAsDataURL(event.target.files[0]);

        await toast.promise(userService.uploadAvatarMe(formData), {
            pending: 'Uploading avatar...',
            success: 'Saved avatar successfully!',
            error: 'Failed avatar uploading'
        });

        setUserInfo(localStorage.getItem('full_name'), imageStringData);
        setAvatar(imageStringData);
    };

    const saveHandler = async e => {
        e.preventDefault();
        setIsSaving(true);
        try {
            userService
                .updateUserMe(fullName, email)
                .then(response => {
                    toast.success('Saved Successfully!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    localStorage.setItem('full_name', response.full_name);
                    localStorage.setItem('email', response.email);
                    setUserInfo(response.full_name, avatar);
                    setIsSaving(false);
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        const message = err.response.data.detail[0].msg;
                        toast.error(message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    } else if (err.response.status === 400) {
                        const message = err.response.data.detail;
                        toast.error(message, {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                    setIsSaving(false);
                });
        } catch {
            setIsSaving(false);
        }
    };
    // End of Save Handler

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div id="myAccount" className="mt-5">
                {localStorage.getItem('isLogged') === 'true' ? (
                    mounted ? (
                        <div className="row">
                            <div className="col-md-12">
                                <div id="student-account">
                                    <h1>My Account</h1>
                                    <p className="subtext-color mt-3">
                                        Manage Account Information.
                                    </p>
                                    <form className="card">
                                        <>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <input
                                                        type="file"
                                                        className="form-control-file"
                                                        id="file-upload"
                                                        onChange={
                                                            handleChangeAvatar
                                                        }
                                                    />
                                                    <span id="myaccount-avatar">
                                                        +
                                                    </span>
                                                    {avatar ? (
                                                        <div className="img-fluid avatar-icon">
                                                            <LazyLoadImage
                                                                className="avatar-icon-image"
                                                                alt="Upload Icon"
                                                                effect="blur"
                                                                src={avatar}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={UploadIcon}
                                                            alt="Upload Icon"
                                                            className="img-fluid upload-icon"
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-md-9 mt-3" />
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-md-6">
                                                    <div className="form-group mt-5">
                                                        <label htmlFor="basic-url">
                                                            Full Name
                                                        </label>
                                                        <div className="input-group mb-3">
                                                            <input
                                                                value={fullName}
                                                                onChange={
                                                                    fullNameChangeHandler
                                                                }
                                                                ref={inputRef}
                                                                type="name"
                                                                className="form-control"
                                                                placeholder="Full Name"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-5">
                                                        <label htmlFor="basic-url">
                                                            Email Address
                                                        </label>
                                                        <div className="input-group mb-3">
                                                            <input
                                                                value={email}
                                                                onChange={
                                                                    emailChangeHandler
                                                                }
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="Email Address"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <label htmlFor="basic-url">
                                                    LMS Role
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={role}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </>

                                        <div className="d-flex justify-content-between mt-1">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-md d-flex align-items-center save-button"
                                                disabled={isSaving}
                                                onClick={saveHandler}
                                            >
                                                {isSaving && (
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                <span className="ml-2">
                                                    Save Changes
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '100px',
                                transform: 'translate(-50%, 0%)'
                            }}
                        >
                            <div className="spinner-border text-muted" />
                        </div>
                    )
                ) : (
                    <Redirect to="/login" />
                )}
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default MyAccount;
