import '../Modals.scss';

// Components
import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const RegenerateQuestionModal = (props) => {
    const { handleSaveQuestions, handleChangeQuestions, handleRegenerate, questions, isGenerating } = props;
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modalContainer"
        >
            <Modal.Body>
                <div id="question-field" className="input-field">
                    <label htmlFor="question">Question</label>
                    <textarea
                        placeholder="Your question"
                        value={questions.question}
                        disabled={!!isGenerating}
                        onChange={handleChangeQuestions}
                        name="question"
                        className="card-input"
                        cols="10"
                        rows="3"
                    />
                    <label htmlFor="answer">Answer</label>
                    <textarea
                        placeholder="Your Answer"
                        value={questions.answer}
                        disabled={!!isGenerating}
                        onChange={handleChangeQuestions}
                        name="answer"
                        className="card-input"
                        cols="10"
                        rows="3"
                    />
                    <label htmlFor="answer">Choices</label>
                    {questions?.choices?.map((element, index) => (
                        <textarea
                            key={index}
                            id={index}
                            placeholder="choices"
                            value={element}
                            disabled={!!isGenerating}
                            onChange={handleChangeQuestions}
                            name="choice"
                            className="card-input-choice"
                            cols="10"
                            rows="3"
                        />
                    ))}
                </div>
                <Button
                    className="p-10 btn btn-primary generateButton"
                    disabled={!!isGenerating}
                    onClick={handleRegenerate}
                >
                    {isGenerating ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        <span>Regenerate</span>
                    )}
                </Button>
                <Button
                    className="p-10 btn btn-primary generateButton"
                    disabled={!!isGenerating}
                    onClick={() => handleSaveQuestions()}
                >
                    Save
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default RegenerateQuestionModal;
