import './EnrollLearnersModal.scss';

// Components
import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const EnrollLearnersModal = (props) => {
    const { onHide, students, show, handleEnrollLearners, isEnrolling, loadingLearners = false } = props;

    const [studentList, setStudentList] = useState([]);
    const [enrollList, setEnrollList] = useState([]);

    const handlePlusLearners = (student) => {
        setEnrollList([...enrollList, student]);
        setStudentList(studentList.filter((el) => el.id !== student.id));
    };

    const handleRemoveLearners = (enroll) => {
        setStudentList([...studentList, enroll]);
        setEnrollList(enrollList.filter((el) => el.id !== enroll.id));
    };

    useEffect(() => {
        setStudentList(students);
    }, [students]);

    useEffect(() => {
        if (!show) {
            setStudentList([]);
            setEnrollList([]);
        }
    }, [show]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modalContainer"
        >
            <Modal.Body>
                <div>
                    <div>
                        <span className="enroll-modal-header">Enroll Learners</span>
                        <p>Select the learners you'd like to enroll in this course.</p>
                        <div className="divider" />
                        <div className="d-flex enroll-modal-learners-container">
                            <div className="enroll-modal-learners">
                                <p>Learners</p>
                                {loadingLearners && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        variant="secondary"
                                    />
                                )}
                                {studentList.map((studentElement) => (
                                    <div
                                        key={studentElement.id}
                                        className="learners-element"
                                        onClick={() => handlePlusLearners(studentElement)}
                                    >
                                        <div className="learners-element-data">
                                            <span>{studentElement.full_name}</span>
                                            <span className="learners-element-email">{studentElement.email}</span>
                                        </div>
                                        <div className="learners-element-button">
                                            <Button className="enroll-modal-plus">
                                                <Icon.Plus />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="divider-line" />
                            <div className="enroll-modal-enroll-learners">
                                <p className="pl-3">Enroll Learners</p>
                                {enrollList.map((enroll) => (
                                    <div
                                        key={enroll.id}
                                        className="learners-element"
                                        onClick={() => handleRemoveLearners(enroll)}
                                    >
                                        <div className="learners-element-data">
                                            <span>{enroll.full_name}</span>
                                            <span className="learners-element-email">{enroll.email}</span>
                                        </div>
                                        <div className="learners-element-button">
                                            <Button variant="danger" className="enroll-modal-plus">
                                                <Icon.Trash />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    className="p-10 btn btn-primary"
                    disabled={!enrollList.length}
                    onClick={() => handleEnrollLearners(enrollList)}
                >
                    {
                        isEnrolling ? (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        ) : (
                            <>
                                Enroll {enrollList.length ? enrollList.length : ''} {enrollList.length > 1 ? 'Learners' : 'Learner'}
                            </>
                        )
                    }
                </Button>
                <Button className="p-10 btn btn-primary" onClick={onHide}>
                    <span>Cancel</span>
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default EnrollLearnersModal;
