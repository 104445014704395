import React, { useState, useEffect } from 'react';

// HOC

// Components
import { Link, Redirect } from 'react-router-dom';

// Assets
import HomeImg1 from 'assets/home1.png';
import HomeImg2 from 'assets/home2.png';
import HomeImg3 from 'assets/home3.png';
import CheckmarkGreenIcon from 'assets/Icons/checkmark-circle-green.svg';
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles
import '../Courses/LMS.scss';

// Utils

const Home = () => {
    // Component State
    const [fullName, setFullName] = useState('');

    useEffect(() => {
        if (localStorage.getItem('full_name') !== 'null') {
            setFullName(localStorage.getItem('full_name'));
        }
    }, []);

    return (
        <div>
            {localStorage.getItem('isLogged') === 'true' ? (
                <div id="LMS" className="mt-5">
                    <h1>
                        Hey <strong>{fullName}</strong>, Welcome to Learnecke
                    </h1>
                    <p className="subtext-color mt-3">Your training journey starts here.</p>

                    <div id="home-cards" className="row">
                        {localStorage.getItem('af_student') === 'CVOT' ? (
                            <div className="lms-cards d-flex p-2 mt-4">
                                {' '}
                                {/* col-sm-12 col-md-4 col-lg-4 mt-4 */}
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg1} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">Learn Courses</h4>
                                        <p className="subtext-color mt-3">
                                            Learn courses tailored specifically for you, selected by your
                                            instructors or admins.
                                        </p>
                                        <Link to="/lms" className="btn btn-primary w-100 mt-2">
                                            Start Learning{' '}
                                            <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="lms-cards d-flex p-2 mt-4">
                            <div className="card">
                                <img className="card-img-top p-1" src={HomeImg2} alt="Card image cap" />
                                <div className="card-body mt-2">
                                    <h4 className="card-title">Attempt Quizzes</h4>
                                    <p className="subtext-color mt-3">
                                        Measure your e-learning with the AI-powered quizzes for each course you
                                        take.
                                    </p>
                                    {localStorage.getItem('af_student') === 'CVOT' ? (
                                        <button
                                            type="button"
                                            className="btn btn-secondary-grey-variation-1 w-100 mt-2 disabled"
                                            disabled
                                        >
                                            Attempt Quizzes
                                        </button>
                                    ) : (
                                        <Link to="/lms" className="btn btn-primary w-100 mt-2">
                                            Attempt Quizzes
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                        {localStorage.getItem('af_student') === 'CVOT' ? (
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg3} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">Repeat</h4>
                                        <p className="subtext-color mt-3">
                                            Repeat the process till you complete all of the courses that you've been
                                            enrolled in.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-secondary w-100 mt-2 disabled"
                                            disabled
                                        >
                                            <img
                                                src={CheckmarkGreenIcon}
                                                alt=""
                                                className="i-check-green img-fluid"
                                            />
                                            <span className="home-course-completed">Course Completed!</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <Redirect to="/login" />
            )}
            {localStorage.getItem('af_student') === 'CVOT' ? <Redirect to="/home" /> : null}
            {localStorage.getItem('lms_role') && localStorage.getItem('lms_role') !== 'student' ? (
                <Redirect to="/app" />
            ) : null}
        </div>
    );
};

export default Home;
