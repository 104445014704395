import React, { useState, useEffect } from 'react';

// Components
import { Redirect } from 'react-router-dom';

// API
import userService from 'services/user.service';

// Styles
import '../Courses/LMS.scss';

const Grades = () => {
    const [userData, setUserData] = useState([]);
    const [countGrades, setCountGrades] = useState(1);
    const [mounted, setMounted] = useState(false);

    // Grades Handler

    useEffect(() => {
        userService.getAllQuizresults()
            .then(response => {
                setCountGrades(response.length);
                setUserData(response);
                setMounted(true);
            })
    }, []);
    // End of Grades Handler

    // Load All/Slice State
    const [sliceNext, setSliceNext] = useState(10);

    useEffect(() => {
        if (countGrades < 10) {
            setSliceNext(countGrades);
        } else {
            setSliceNext(10);
        }
    }, [countGrades]);

    const handleGradesLoad = () => {
        if (sliceNext < countGrades) {
            setSliceNext(sliceNext + countGrades - sliceNext);
        }
    };

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                mounted ? (
                    <div id="LMS" className="mt-5">
                        <h1>
                            <strong>Grades</strong>
                        </h1>
                        <p className="subtext-color mt-3">List of all grades associated with your account.</p>

                        <div className="tableFixHead mt-5">
                            <table className="table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Article</th>
                                        <th scope="col">Total Questions</th>
                                        <th scope="col">Your Scores</th>
                                        <th scope="col">Results</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData.length !== 0 ? (
                                        userData.slice(0, sliceNext).map((user) => (
                                            <tr key={user.id} className="grade-item mt-4">
                                                <td>{user.lesson.title}</td>
                                                <td>{`${user.lesson.quizitems_num}.0`}</td>
                                                <td>{`${user.score}.0`}</td>
                                                <td>
                                                    {user.result === true ? (
                                                        <span>Passed</span>
                                                    ) : (
                                                        <strong>Failed</strong>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <p className="mt-4">No grades available.</p>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            {countGrades >= 10 ? (
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-center"
                                        onClick={handleGradesLoad}
                                    >
                                        Load All
                                    </button>
                                </div>
                            ) : null}
                            <div className="align-self-center">
                                <span className="subtext-color">
                                    <strong>
                                        {sliceNext} /{countGrades}
                                    </strong>{' '}
                                    quiz results.
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '100px',
                            transform: 'translate(-50%, 0%)',
                        }}
                    >
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Grades;
