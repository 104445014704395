import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import * as Icon from 'react-bootstrap-icons';

// Components
import * as ReactBootStrap from 'react-bootstrap';
import { GlobalContext } from 'stores/globalStore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Styles
import './Navbar.scss';

// Icons
import NotificationIcon from 'assets/Icons/notification.svg';
import SearchIcon from 'assets/Icons/search.svg';

// Utils
import { getAbbreviateName } from 'utils/getAbbreviateName';

const Navbar = () => {
    const { store, dispatch } = useContext(GlobalContext);

    const { userInfo } = store;

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const Logout = () => {
        localStorage.clear();
    };

    const openOverlay = () => {
        dispatch({
            type: 'updateSidebar',
            showSidebar: true
        });
    };

    return (
        <div id="Navbar" className="pt-3">
            <ReactBootStrap.Navbar>
                {/* <Container className="extended"> */}
                <div id="search-lms" className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <img
                                src={SearchIcon}
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
                <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                    <ReactBootStrap.Nav className="mr-auto" />
                    <ReactBootStrap.Nav>
                        <ul className="navbar-nav">
                            <li id="notification" className="nav-item pointer">
                                <img
                                    src={NotificationIcon}
                                    alt="Notification Icon"
                                    className="img-fluid"
                                />
                            </li>
                            <li className="nav-item-group">
                                {localStorage.getItem('isLogged') === 'true' ? (
                                    <>
                                        <Dropdown
                                            isOpen={dropdownOpen}
                                            toggle={toggle}
                                        >
                                            <DropdownToggle id="avatar_dropdown">
                                                {userInfo?.avatar ? (
                                                    <LazyLoadImage
                                                        className="img-fluid avatar-icon"
                                                        alt="Upload Icon"
                                                        effect="blur"
                                                        src={userInfo.avatar}
                                                    />
                                                ) : (
                                                    getAbbreviateName(
                                                        userInfo?.fullName
                                                    )
                                                )}
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem text>
                                                    <Link
                                                        to="/"
                                                        className="nav-link"
                                                    >
                                                        Website
                                                    </Link>
                                                </DropdownItem>
                                                {localStorage.getItem(
                                                    'lms_role'
                                                ) === 'admin' ? null : (
                                                    <DropdownItem text>
                                                        <Link
                                                            to="/home"
                                                            className="nav-link"
                                                        >
                                                            Dashboard
                                                        </Link>
                                                    </DropdownItem>
                                                )}
                                                {localStorage.getItem(
                                                    'lms_role'
                                                ) === 'admin' ||
                                                localStorage.getItem(
                                                    'lms_role'
                                                ) === 'instructor' ? (
                                                    <DropdownItem text>
                                                        <Link
                                                            to="/app"
                                                            className="nav-link"
                                                        >
                                                            Admin Desk
                                                        </Link>
                                                    </DropdownItem>
                                                ) : null}
                                                <DropdownItem divider />
                                                <DropdownItem text>
                                                    <Link
                                                        onClick={Logout}
                                                        className="nav-link"
                                                        to="/login"
                                                    >
                                                        Logout
                                                    </Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <div className="nav-mobile">
                                            <span
                                                className="nav-mobile-icon"
                                                onClick={openOverlay}
                                            >
                                                <Icon.List />
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <Link
                                        className="nav-link btn btn-secondary btn-rounded-sm"
                                        to="/login"
                                    >
                                        <strong>Login</strong>
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </ReactBootStrap.Nav>
                </ReactBootStrap.Navbar.Collapse>
                {/* </Container> */}
            </ReactBootStrap.Navbar>
        </div>
    );
};

export default Navbar;
