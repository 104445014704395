import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import { ToastContainer, toast } from 'react-toastify';
import userService from 'services/user.service';
import End from './End';

// API

const LoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 99999999 !important;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalLoader = styled.div`
    width: 10px;
    height: 10px;
    padding: 1rem;
    background: #7754f8;
    border-radius: 50%;
    animation: 1s Loader infinite;
    animation-direction: alternate;
`;

const ModalLoaderIcon = styled.div`
    width: 7px;
    height: 7px;
    padding: 1rem;
    background: #7754f8;
    border-radius: 50%;
    animation: 1s LoaderIN infinite;
    animation-direction: alternate;
`;

const QuizWindow = styled.div`
    text-align: left;
    // font-size: clamp(20px, 2.5vw, 24px);
`;

const Options = styled.div`
    @media screen and (min-width: 1180px) {
        width: 100%;
    }

    & .option-container {
        display: flex;
    }
`;

const Option = styled.button`
    border: 1px solid transparent;
    padding: 15px 15px;
    text-decoration: none;
    text-align: left;
    color: var(--text-color);
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
    font-size: 18px;

    &:hover {
        background: #f1f1f1;
        // color: var(--white-color);
        // background-color: var(--secondary-color);
        // border: 1px solid var(--secondary-color);
    }
`;

const Question = styled.h3``;

const QA = ({ lessonId }) => {
    const [quiz, setQuiz] = useState([]);
    const [number, setNumber] = useState(0);
    const [countOptions, setCountOptions] = useState();
    const [pts, setPts] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [articleName, setArticleName] = useState('');
    const [durationMax, setDurationMax] = useState(0);
    const [isTimeExpired, setIsTimeExpired] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [timePerQuiz, setTimePerQuiz] = useState(new Date());

    // Mutliple Choice Answer Handlers
    const pickAnswer = e => {
        let point = 0;
        const userAnswer = e.target.innerText;

        if (quiz[number].answer === userAnswer) {
            setPts(pts + 1);
            point = 1;
        }

        // Resetting Question Reference
        setShowRef(false);
        const questionId = quiz[number].id;
        const qSeqId = number + 1;
        const choiceId = e.target.id;
        const time = new Date() - timePerQuiz;
        userService
            .updateLearnerAnalytics(
                sessionId,
                questionId,
                qSeqId,
                choiceId,
                time / 1000,
                point,
                localStorage.getItem('user_id')
            )
            .then(response => {
                setTimePerQuiz(new Date());
                setNumber(number + 1);
            });
    };

    // Short Answer Handlers
    let userAnswer = '';
    const handleShortAnswer = e => {
        userAnswer = e.target.value;
    };
    const submitShortAnswer = () => {
        if (quiz[number].answer === userAnswer) setPts(pts + 1);
        setNumber(number + 1);
    };

    useEffect(() => {
        setMounted(false);
        userService.getLessonById(lessonId).then(response => {
            setDurationMax(response.duration_max);
            setQuiz(response.quizitems);
            setCountOptions(response.quizitems.length);
            setArticleName(response.title);
            setSessionId(localStorage.getItem('sess_id'));
            setMounted(true);
        });
    }, []);

    // Show Reference Handler
    const [showRef, setShowRef] = useState(false);

    const handleReference = () => {
        setShowRef(!showRef);
    };

    const countDownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // call callback function for completed
            setIsTimeExpired(true);
            toast.error('Time Expired!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            // Render a complete state
            return <p className="subtext-color"> Time Expired!</p>;
        } else {
            return (
                <p className="subtext-color">
                    {minutes > 9 ? minutes : `0${minutes}`}:
                    {seconds > 9 ? seconds : `0${seconds}`}
                </p>
            );
        }
    };

    const MemoCountDown = useMemo(
        () => (
            <Countdown
                date={Date.now() + durationMax * 1000}
                renderer={countDownRenderer}
                zeroPadTime={2}
            />
        ),
        [durationMax]
    );

    return (
        <>
            {mounted ? (
                <QuizWindow className="qa">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className="mt-5">
                        {quiz[number] && isTimeExpired === false ? (
                            <>
                                <h1>
                                    <strong>Quiz</strong> {articleName}
                                </h1>
                                <p className="subtext-color mt-3">
                                    Upon quiz completion you'll be able to see
                                    the quiz results in <i>"Grades"</i>.
                                </p>

                                <div className="qa-wrapper mt-5">
                                    <div className="d-flex justify-content-between">
                                        <p className="subtext-color">
                                            Question {number + 1} /
                                            {countOptions}
                                        </p>
                                        {MemoCountDown}
                                    </div>
                                    <Question
                                        dangerouslySetInnerHTML={{
                                            __html: quiz[number].question
                                        }}
                                    />
                                    {quiz[number].type === 'multiChoice' ? (
                                        <Options
                                            id="quiz-options"
                                            className="mt-5 mb-5"
                                        >
                                            {quiz[number].choices.map(
                                                (item, index) => (
                                                    <div className="option-container">
                                                        <span id="option-index">
                                                            {index + 1}
                                                        </span>
                                                        <Option
                                                            key={index}
                                                            id={item.id}
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item.text
                                                            }}
                                                            onClick={pickAnswer}
                                                        />
                                                    </div>
                                                )
                                            )}
                                            {[quiz[number]].map(
                                                (item, index) => (
                                                    <div
                                                        key={index}
                                                        className="mt-5"
                                                    >
                                                        <div className="divider" />
                                                        <h5 className="mt-5">
                                                            Don't know the
                                                            answer? Learn it.
                                                        </h5>
                                                        <div className="form-check no-user-select mt-4">
                                                            <input
                                                                checked={
                                                                    !!showRef
                                                                }
                                                                className="form-check-input pointer"
                                                                type="checkbox"
                                                                id="showReference"
                                                                onClick={
                                                                    handleReference
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label pointer"
                                                                htmlFor="showReference"
                                                            >
                                                                Show Reference
                                                            </label>
                                                        </div>
                                                        {showRef ? (
                                                            <div>
                                                                <p className="subtext-color mt-4">
                                                                    {item.ref}
                                                                </p>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )
                                            )}
                                        </Options>
                                    ) : null}
                                    {quiz[number].type === 'shortAnswer' ? (
                                        <Options
                                            id="quiz-options"
                                            className="mt-5"
                                        >
                                            {quiz[number].options.map(
                                                (item, index) => (
                                                    <div className="form-group mt-4">
                                                        <input
                                                            className="form-control"
                                                            id="exampleInputPassword1"
                                                            onChange={
                                                                handleShortAnswer
                                                            }
                                                            type="text"
                                                            placeholder={`Correct: ${item}`}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary mt-5"
                                                            onClick={
                                                                submitShortAnswer
                                                            }
                                                        >
                                                            Submit Answer
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </Options>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>
                    {number === countOptions || isTimeExpired === true ? (
                        <End
                            pts={pts}
                            countOptions={countOptions}
                            articleName={articleName}
                            lessonId={lessonId}
                        />
                    ) : null}
                </QuizWindow>
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '100px',
                        transform: 'translate(-50%, 0%)'
                    }}
                >
                    <div className="spinner-border text-muted" />
                </div>
            )}
        </>
    );
};

export default QA;
