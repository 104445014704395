import React, { useState, useEffect, useContext, useMemo } from 'react';

// Components
import { Heatmap } from 'components';
import { Link, Redirect } from 'react-router-dom';
import { GlobalContext } from 'stores/globalStore';
import { round2DecimalPlaces } from 'utils/round2DecimalPlaces';

// APIs
import apiHeaders from 'services/apiHeader';
import apiurls from 'services/apiRoutes';
import axios from 'axios';
import SummaryArea from './SummaryArea';
import { CollapseTable, TimeSlider, WordCloud } from './components';

const Management = () => {
    const { store, dispatch } = useContext(GlobalContext);
    const { users } = store;

    const setUsers = (param) => {
        dispatch({
            type: 'updateUsers',
            users: param,
        });
    };

    const [mounted, setMounted] = useState(false);
    const [dayRange, setDayRange] = useState([1, 5]);
    const [resData, setResData] = useState([]);
    const [filterDataByDay, setFilterDataByDay] = useState([]);
    const [weakStrength, setWeakStrength] = useState([]);
    const [filteredWeak, setFilteredWeak] = useState([]);
    const [filteredStrength, setFilteredStrength] = useState([]);
    const [arrayStrengthWeakness, setArrayStrehngthWeakness] = useState([]);

    const [summaryData, setSummaryData] = useState({
        avg_quiz_cmplte: 0,
        tot_quiz_cmplte: 0,
        avg_qn_answ: 0,
        tot_qn_answ: 0,
        avg_pct_qn_answ_cor: 0,
        tot_qn_answ_cor: 0,
        tot_qn_answ_incor: 0,
        avg_crse_cmplte: 0,
        tot_crse_cmplte: 0,
        avg_tm_per_crse: 0,
        tot_tm_per_crse: 0,
        avg_tm_per_quiz: 0,
        tot_tm_per_quiz: 0,
        avg_qn_atmp: 0,
        tot_qn_atmp: 0,
        tm_per_qn: 0,
        avg_pts_per_qn: 0,
        avg_pts_per_quiz: 0,
        tot_pts: 0,
    });

    const handleChangeTimeBar = (value) => {
        const tempDayRange = [value[0] / 25 + 1, value[1] / 25 + 1];
        setDayRange(tempDayRange);
    };

    function compareMax(a, b) {
        // Use toUpperCase() to ignore character casing
        const valueA = a.value;
        const valueB = b.value;

        let comparison = 0;
        if (valueA < valueB) {
            comparison = 1;
        } else if (valueA > valueB) {
            comparison = -1;
        }
        return comparison;
    }

    function compareMin(a, b) {
        // Use toUpperCase() to ignore character casing
        const valueA = a.value;
        const valueB = b.value;

        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return comparison;
    }

    const handleFilterWeakStrength = (data) => {
        const tempWeak = [];
        const tempStrength = [];
        const dayFilter = data.filter(
            (item) => Number(item.day) >= Number(dayRange[0]) && Number(item.day) <= Number(dayRange[1])
        );

        const usersCheck = users.filter((item) => item.check);
        for (let i = 0; i < dayFilter.length; i++) {
            for (let j = 0; j < usersCheck.length; j++) {
                if (dayFilter[i].userId === usersCheck[j].userId) {
                    for (let k = 0; k < dayFilter[i].strength.length; k++) {
                        tempStrength.push(dayFilter[i].strength[k]);
                    }
                    for (let k = 0; k < dayFilter[i].weakness.length; k++) {
                        tempWeak.push(dayFilter[i].weakness[k]);
                    }
                }
            }
        }

        const sortTempStrength = tempStrength.sort(compareMax);
        const sortTempWeak = tempWeak.sort(compareMin);

        const filteredStrengthArr = sortTempStrength.reduce((acc, current) => {
            const x = acc.find((item) => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            }
            return acc;
        }, []);

        const filteredWeaknessArr = sortTempWeak.reduce((acc, current) => {
            const x = acc.find((item) => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            }
            return acc;
        }, []);

        // var max = Math.max.apply(null, tempStrength);
        setFilteredStrength(filteredStrengthArr.slice(0, 3));
        setFilteredWeak(filteredWeaknessArr.slice(0, 3));
        // return {
        //   strength: filteredStrengthArr,
        //   weakness: filteredWeaknessArr
        // }

        const arrayStrengthWeakness = [...filteredStrengthArr, ...filteredWeaknessArr];
        setArrayStrehngthWeakness(arrayStrengthWeakness);
    };

    const filterByDay = (data) => {
        const tempFilterDataByDay = [];
        const filterData = data.filter(
            (item) => Number(item.day) >= Number(dayRange[0]) && Number(item.day) <= Number(dayRange[1])
        );
        for (let i = 3; i < 6; i++) {
            const user_1 = filterData.filter((item) => item.userId === i);
            let temp_courses_completed = 0;
            let temp_points_per_question = 0;
            let temp_points_per_quiz = 0;
            let temp_question_attempts = 0;
            let temp_questions_answered = 0;
            let temp_questions_answered_correct = 0;
            let temp_quiz_attempts_completed = 0;
            let temp_time_per_course = 0;
            let temp_time_per_question = 0;
            let temp_time_per_quiz = 0;
            let temp_total_points = 0;
            for (let i = 0; i < user_1.length; i++) {
                temp_courses_completed += user_1[i].courses_completed;
                temp_points_per_question += user_1[i].points_per_question;
                temp_points_per_quiz += user_1[i].points_per_quiz;
                temp_question_attempts += user_1[i].question_attempts;
                temp_questions_answered += user_1[i].questions_answered;
                temp_questions_answered_correct += user_1[i].questions_answered_correct;
                temp_quiz_attempts_completed += user_1[i].quiz_attempts_completed;
                temp_time_per_course += user_1[i].time_per_course;
                temp_time_per_question += user_1[i].time_per_question;
                temp_time_per_quiz += user_1[i].time_per_quiz;
                temp_total_points += user_1[i].total_points;
            }
            tempFilterDataByDay.push({
                email: user_1[0].email,
                id: user_1[0].id,
                name: user_1[0].name,
                rank: user_1[0].rank,
                unit: user_1[0].unit,
                userId: user_1[0].userId,
                courses_completed: round2DecimalPlaces(temp_courses_completed),
                points_per_question: round2DecimalPlaces(temp_points_per_question / user_1.length),
                points_per_quiz: round2DecimalPlaces(temp_points_per_quiz / user_1.length),
                question_attempts: round2DecimalPlaces(temp_question_attempts),
                questions_answered: round2DecimalPlaces(temp_questions_answered),
                questions_answered_correct: round2DecimalPlaces(temp_questions_answered_correct),
                quiz_attempts_completed: round2DecimalPlaces(temp_quiz_attempts_completed),
                time_per_course: round2DecimalPlaces(temp_time_per_course / user_1.length),
                time_per_question: round2DecimalPlaces(temp_time_per_question / user_1.length),
                time_per_quiz: round2DecimalPlaces(temp_time_per_quiz / user_1.length),
                total_points: round2DecimalPlaces(temp_total_points),
            });
        }

        // setFilterDataByDay(tempFilterDataByDay);
        return tempFilterDataByDay;
    };

    const calcSummaryData = (members) => {
        let tot_quiz_cmplte = 0;
        let tot_qn_answ = 0;
        let tot_qn_answ_cor = 0;
        let tot_crse_cmplte = 0;
        let tot_tm_per_crse = 0;
        let tot_tm_per_quiz = 0;
        let tot_qn_atmp = 0;
        let tot_tm_per_qn = 0;
        let tot_pts_per_qn = 0;
        let tot_pts_per_quiz = 0;
        let tot_pts = 0;

        members.forEach((element) => {
            tot_quiz_cmplte += element.quiz_attempts_completed;
            tot_qn_answ += element.questions_answered;
            tot_qn_answ_cor += element.questions_answered_correct;
            tot_crse_cmplte += element.courses_completed;
            tot_tm_per_crse += element.time_per_course;
            tot_tm_per_quiz += element.time_per_quiz;
            tot_qn_atmp += element.question_attempts;
            tot_tm_per_qn += element.time_per_question;
            tot_pts_per_qn += element.points_per_question;
            tot_pts_per_quiz += element.points_per_quiz;
            tot_pts += element.total_points;
        });

        const memNumber = members.length;
        setSummaryData({
            ...summaryData,
            avg_quiz_cmplte: memNumber !== 0 ? round2DecimalPlaces(tot_quiz_cmplte / memNumber) : 0,
            tot_quiz_cmplte,
            avg_qn_answ: memNumber !== 0 ? round2DecimalPlaces(tot_qn_answ / memNumber) : 0,
            tot_qn_answ: round2DecimalPlaces(tot_qn_answ),
            avg_pct_qn_answ_cor: tot_qn_answ !== 0 ? round2DecimalPlaces(tot_qn_answ_cor / tot_qn_answ) : 0,
            tot_qn_answ_cor: round2DecimalPlaces(tot_qn_answ_cor),
            tot_qn_answ_incor: round2DecimalPlaces(tot_qn_answ - tot_qn_answ_cor),
            avg_crse_cmplte: memNumber !== 0 ? round2DecimalPlaces(tot_crse_cmplte / memNumber) : 0,
            tot_crse_cmplte: round2DecimalPlaces(tot_crse_cmplte),
            avg_tm_per_crse: memNumber !== 0 ? round2DecimalPlaces(tot_tm_per_crse / memNumber) : 0,
            tot_tm_per_crse: round2DecimalPlaces(tot_tm_per_crse),
            avg_tm_per_quiz: memNumber !== 0 ? round2DecimalPlaces(tot_tm_per_quiz / memNumber) : 0,
            tot_tm_per_quiz: round2DecimalPlaces(tot_tm_per_quiz),
            tot_qn_atmp: round2DecimalPlaces(tot_qn_atmp),
            avg_qn_atmp: memNumber !== 0 ? round2DecimalPlaces(tot_qn_atmp / memNumber) : 0,
            tm_per_qn: memNumber !== 0 ? round2DecimalPlaces(tot_tm_per_qn / memNumber) : 0,
            avg_pts_per_qn: memNumber !== 0 ? round2DecimalPlaces(tot_pts_per_qn / memNumber) : 0,
            avg_pts_per_quiz: memNumber !== 0 ? round2DecimalPlaces(tot_pts_per_quiz / memNumber) : 0,
            tot_pts: round2DecimalPlaces(tot_pts),
        });
    };

    useEffect(async () => {
        await axios.get(apiurls.analysisStudents, { headers: apiHeaders }).then((res) => {
            setFilterDataByDay(filterByDay(res.data));
            setResData(res.data);
            setUsers(
                filterByDay(res.data).map((element) => ({
                    ...element,
                    check: true,
                }))
            );
        });

        await axios.get(apiurls.weaknessAndStrength, { headers: apiHeaders }).then((res) => {
            setWeakStrength(res.data);
            handleFilterWeakStrength(res.data);
            setDayRange([1, 5]);
        });

        setMounted(true);
    }, []);

    useEffect(() => {
        calcSummaryData(users.filter((user) => user.check));
        handleFilterWeakStrength(weakStrength);
    }, [users]);

    useEffect(() => {
        if (resData.length > 0) {
            const temp = filterByDay(resData);
            const tempFilterData = [];

            for (let i = 0; i < temp.length; i++) {
                const checkValue = users.filter((user) => Number(user.userId) === Number(temp[i].userId))[0].check;
                tempFilterData.push({
                    ...temp[i],
                    check: checkValue,
                });
            }

            setUsers(tempFilterData);
            handleFilterWeakStrength(weakStrength);
        }
    }, [dayRange]);

    const MemoWordCloud = useMemo(
        () => (
            <>
                <WordCloud arrayStrengthWeakness={arrayStrengthWeakness} />
            </>
        ),
        [arrayStrengthWeakness]
    );

    return (
        <>
                {mounted ? (
                    <div>
                        {localStorage.getItem('isLogged') === 'true' ? (
                            <div id="Students" className="mt-5">
                                <h1>
                                    <strong>Training Management</strong>
                                </h1>
                                <p className="subtext-color mt-3">Summary</p>
                                <div>
                                    {filteredStrength.length !== 0 && (
                                        <div className="summary-text-container">
                                            <span>
                                                <strong>
                                                    Based on the data analyzed so far, your learners’ three biggest
                                                    strengths are:&nbsp;
                                                </strong>
                                            </span>
                                            {filteredStrength.map((element, index) => (
                                                <span key={index}>
                                                    <strong>
                                                        {element.name}
                                                        {index !== 2 && <span>,&nbsp;</span>}
                                                    </strong>
                                                </span>
                                            ))}
                                            <span>
                                                <strong>, and your learners’ three biggest weaknesses are:</strong>
                                            </span>
                                            &nbsp;
                                            {filteredWeak.map((element, index) => (
                                                <span key={index}>
                                                    <strong>
                                                        {element.name}
                                                        {index !== 2 && <span>,&nbsp;</span>}
                                                    </strong>
                                                </span>
                                            ))}
                                            .
                                        </div>
                                    )}
                                </div>
                                <div className="divider" />
                                <p className="subtext-color mt-3">Stats</p>
                                <div className="summary-section">
                                    <div className="summary-subsection-wrapper">
                                        <div className="summary-subsection">
                                            <SummaryArea
                                                title="Quiz attempts completed"
                                                total={summaryData.tot_quiz_cmplte}
                                                average={summaryData.avg_quiz_cmplte}
                                            />
                                            <SummaryArea
                                                title="Courses completed"
                                                total={summaryData.tot_crse_cmplte}
                                                average={summaryData.avg_crse_cmplte}
                                            />
                                        </div>
                                        <div className="summary-subsection">
                                            <SummaryArea
                                                title="Question attempts"
                                                total={summaryData.tot_qn_atmp}
                                                average={summaryData.avg_qn_atmp}
                                            />
                                            <SummaryArea
                                                title="Time per course"
                                                total={summaryData.tot_tm_per_crse}
                                                average={summaryData.avg_tm_per_crse}
                                            />
                                        </div>
                                        <div className="summary-subsection">
                                            <SummaryArea
                                                title="Time per quiz"
                                                total={summaryData.tot_tm_per_quiz}
                                                average={summaryData.avg_tm_per_quiz}
                                            />
                                            <SummaryArea title="Time per question" average={summaryData.tm_per_qn} />
                                        </div>
                                        <div className="summary-subsection">
                                            <SummaryArea
                                                title="Points"
                                                total={summaryData.tot_pts}
                                                pointsAvgPerQuestion={summaryData.avg_pts_per_qn}
                                                pointsAvgPerQuiz={summaryData.avg_pts_per_quiz}
                                            />
                                        </div>
                                    </div>
                                    <div className="divider-line" />
                                    <div className="summary-subsection-wrapper-2">
                                        <SummaryArea
                                            title="Questions answered"
                                            total={summaryData.tot_qn_answ}
                                            average={summaryData.avg_qn_answ}
                                            correct={summaryData.tot_qn_answ_cor}
                                            incorrect={summaryData.tot_qn_answ_incor}
                                            percent={summaryData.avg_pct_qn_answ_cor}
                                        />
                                    </div>
                                </div>
                                <div className="divider" />
                                <p className="subtext-color mt-3">Subject area strengths and weaknesses</p>
                                <Heatmap pageType="management" dayRange={dayRange} />
                                {filteredStrength.length !== 0 && (
                                    <div>
                                        {MemoWordCloud}
                                        <p className="subtext-color text-center">
                                            Larger means more challenging. Redder means challenging for a longer time
                                            period.
                                        </p>
                                    </div>
                                )}
                                <div className="divider" />
                                <p className="subtext-color mt-3">Time Period</p>
                                <div>
                                    <TimeSlider handleChangeTimeBar={handleChangeTimeBar} />
                                </div>
                                <div className="divider" />
                                <div>
                                    <p className="subtext-color mt-3">Students</p>
                                    <CollapseTable dayRange={dayRange} />
                                </div>
                            </div>
                        ) : (
                            <Redirect to="/login" />
                        )}
                    </div>
                ) : (
                    <div style={{ position: 'absolute', left: '50%', top: '100px', transform: 'translate(-50%, 0%)' }}>
                        <div className="spinner-border text-muted" />
                    </div>
                )}
        </>
    );
};

export default Management;
