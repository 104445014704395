import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

// Styles
import './Enrollments.scss';

// APIs
import userService from 'services/user.service';

// Utils
import { convertDateFormat } from 'utils/convertDateFormat';

const Enrollments = (props) => {
    const { history } = props;
    const [coursesData, setCoursesData] = useState([]);
    const [courseNumber, setCoursesNumber] = useState(0);
    const [learnersNumber, setLearnersNumber] = useState(0);
    const [countGrades, setCountGrades] = useState(1);
    const [mounted, setMounted] = useState(false);

    // Load All/Slice State
    const [sliceNext, setSliceNext] = useState(10);

    const handleCourseClick = (courseId) => {
        history.push(`/enrollments/course/learners/${courseId}`);
    };

    useEffect(() => {
        userService.getAllCourses().then((response) => {
            let tempLearnersNum = 0;
            setCoursesData(response);
            setCoursesNumber(response.length);
            setCountGrades(response.length);
            response.forEach((course) => {
                tempLearnersNum += course.users_assigned_num;
            });
            setLearnersNumber(tempLearnersNum);
            setMounted(true);
        });
    }, []);

    useEffect(() => {
        if (countGrades < 10) {
            setSliceNext(countGrades);
        } else {
            setSliceNext(10);
        }
    }, [countGrades]);

    const handleGradesLoad = () => {
        if (sliceNext < countGrades) {
            setSliceNext(sliceNext + countGrades - sliceNext);
        }
    };

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                mounted ? (
                    <div id="Students" className="mt-5">
                        <h1>
                            <strong>Courses</strong>
                        </h1>
                        <p className="subtext-color mt-3">
                            <span>{courseNumber} Courses</span>
                            <span className="ml-3">{learnersNumber} Learners</span>
                        </p>
                        <div className="divider" />
                        <div className="tableFixHead mt-5">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Course</th>
                                        <th scope="col">Author</th>
                                        <th scope="col">Learners</th>
                                        <th scope="col">Updated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coursesData.map((course) => (
                                        <tr
                                            key={course.id}
                                            className="course-row"
                                            onClick={() => handleCourseClick(course.id)}
                                        >
                                            <td>
                                                <img
                                                    src={course.image}
                                                    alt="Course Image"
                                                    className="img-fluid course-image mr-3"
                                                />
                                                <span>{course.title}</span>
                                            </td>
                                            <td>{course.owner.full_name}</td>
                                            <td>{course.users_assigned_num}</td>
                                            <td>{convertDateFormat(course.modified_timestamp)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            {countGrades >= 10 ? (
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-center"
                                        onClick={handleGradesLoad}
                                    >
                                        Load All
                                    </button>
                                </div>
                            ) : null}
                            <div className="align-self-center">
                                <span className="subtext-color">
                                    <strong>
                                        {sliceNext} /{countGrades}
                                    </strong>{' '}
                                    {
                                        sliceNext === 1 ?
                                        'course.' :
                                        'courses.'
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '100px',
                            transform: 'translate(-50%, 0%)',
                        }}
                    >
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Enrollments;
