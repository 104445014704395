import React, { useEffect, useState } from 'react';
import userService from 'services/user.service';
import { Link } from 'react-router-dom';

// Styles
import './AllQuestions.scss';

const AllQuestions = (props) => {
    const [questionData, setQuestionData] = useState([]);
    const [editQuestionId, setEditQuestionId] = useState(null);
    const [sliceNext, setSliceNext] = useState(10);

    const handleDeleteQuestion = (questionId) => {
        const confirm = window.confirm('Are you sure you want to delete this question?');
        if (confirm) {
            userService.deleteQuestion(questionId, localStorage.getItem('user_id')).then((res) => {
                const temp = questionData.filter((question) => Number(question.id) !== Number(res.id));
                setQuestionData(temp);
            });
        }
    };

    const handleEditQuestion = (questionId) => {
        setEditQuestionId(questionId);
    };

    const handleCancelSaveQuestion = () => {
        setEditQuestionId(null);
    };

    const handleSaveQuestion = (questionId) => {
        setEditQuestionId(null);
        const saveQuestion = questionData.filter((question) => Number(question.id) === Number(questionId))[0];
        userService.updateQuestion(questionId, saveQuestion);
    };

    const handleChangeTextArea = (event, questionId, elementId) => {
        const temp = [...questionData];
        temp.forEach((tempElement) => {
            if (tempElement.id === questionId) {
                if (event.target.id === 'question') {
                    tempElement.question = event.target.value;
                } else if (event.target.id === 'answer') {
                    tempElement.answer = event.target.value;
                } else {
                    tempElement.choices[Number(event.target.id)] = event.target.value;
                }
            }
        });
        setQuestionData(temp);
    };

    const handleGradesLoad = () => {
        if (sliceNext < questionData.length) {
            setSliceNext(questionData.length);
        }
    };

    useEffect(() => {
        userService.getQuestions(localStorage.getItem('user_id')).then((res) => {
            setQuestionData(res);
        });
    }, []);

    return (
        <>
            <Link to="/app">
                <nav className="headerNav">
                    <img src="/static/media/learneckeLogo.233f9573.png" alt="header image" id="logo" />
                </nav>
            </Link>
            <h1 className="questionHeader">Your questions</h1>
            <div id="table-wrap">
                <table className="questionTable">
                    <tr>
                        <th className="questionTableHeader">ID</th>
                        <th colSpan={2} className="questionTableHeader">
                            QUESTIONS
                        </th>
                        <th className="questionTableHeader">ACTIONS</th>
                    </tr>
                    {questionData.slice(0, sliceNext).map((element, index) => (
                        <>
                            <tr id={element.id}>
                                <td rowSpan={element.choices.length + 2} className="questionRow">
                                    {index + 1}
                                </td>
                                <td id={element.id} className="questionRow">
                                    Question
                                </td>
                                <td id={element.id}>
                                    {editQuestionId === element.id ? (
                                        <textarea
                                            className="questionTextArea"
                                            id="question"
                                            value={element.question}
                                            onChange={(e) => handleChangeTextArea(e, element.id, element.id)}
                                        />
                                    ) : (
                                        element.question
                                    )}
                                </td>
                                <td rowSpan={element.choices.length + 2} className="actionRow">
                                    {editQuestionId === element.id ? (
                                        <>
                                            <button
                                                className="edit-card btn-primary"
                                                onClick={() => handleSaveQuestion(element.id)}
                                            >
                                                Save
                                            </button>
                                            <button
                                                className="delete-card"
                                                onClick={() => handleCancelSaveQuestion(element.id)}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="edit-card"
                                                onClick={() => handleEditQuestion(element.id)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="delete-card btn-danger"
                                                onClick={() => handleDeleteQuestion(element.id)}
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td id={element.id} className="questionRow">
                                    Answer
                                </td>
                                <td id={element.id}>
                                    {editQuestionId === element.id ? (
                                        <textarea
                                            className="questionTextArea"
                                            id="answer"
                                            value={element.answer}
                                            onChange={(e) => handleChangeTextArea(e, element.id, element.id)}
                                        />
                                    ) : (
                                        element.answer
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td rowSpan={element.choices.length} id={element.id} className="questionRow">
                                    Choices
                                </td>
                                <td>
                                    {editQuestionId === element.id ? (
                                        <textarea
                                            className="questionTextArea"
                                            id="0"
                                            value={element.choices[0]}
                                            onChange={(e) => handleChangeTextArea(e, element.id, element.id)}
                                        />
                                    ) : (
                                        element.choices[0]
                                    )}
                                </td>
                            </tr>
                            {element.choices.slice(1, element.choices.length).map((choice, choiceId) => (
                                <tr>
                                    <td>
                                        {editQuestionId === element.id ? (
                                            <textarea
                                                className="questionTextArea"
                                                id={choiceId + 1}
                                                value={choice}
                                                onChange={(e) => handleChangeTextArea(e, element.id, element.id)}
                                            />
                                        ) : (
                                            choice
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </>
                    ))}
                </table>
                <div className="d-flex justify-content-between mt-4">
                    {questionData?.length > 10 && questionData?.length !== sliceNext ? (
                        <div>
                            <button type="button" className="btn btn-secondary btn-center" onClick={handleGradesLoad}>
                                Load All
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default AllQuestions;
