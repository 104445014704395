import React from 'react';

// Assets
import Logo from 'assets/learneckeLogo.png';
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles
import './Footer.scss';

const Footer = () => (
    <div id="Footer">
        <div className="container extended">
            <div className="d-flex justify-content-between flex-sm-row flex-column">
                <div>
                    <img src={Logo} alt="Growth Engine AI Logo" className="img-fluid" />
                    <p className="copyright mt-3">© Growth Engine, Inc. All rights reserved.</p>
                </div>
                <div className="align-self-center">
                    <div className="footer-btns">
                        <a href="#" className="btn btn-md btn-primary-hero">
                            See it in Action <img src={ArrowWhiteIcon} alt="Icon Arrow" className="i-arrow img-fluid" />
                        </a>
                        <a href="#" className="btn btn-md btn-secondary">
                            Contact
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
