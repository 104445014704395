import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

// Components
import { Link, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';

// Styles
import './Students.scss';
import 'react-toastify/dist/ReactToastify.css';

// APIs
import userService from 'services/user.service';

const Program = () => {
    // State
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Input Handlers
    function fullNameChangeHandler(event) {
        setFullName(event.target.value);
    }
    function emailChangeHandler(event) {
        setEmail(event.target.value);
    }
    function passwordChangeHandler(event) {
        setPassword(event.target.value);
    }

    // Add Student Function
    const addStudent = async (e) => {
        e.preventDefault();
        try {
            if (fullName !== '' && email !== '' && password !== '') {
                setIsLoading(true);
                userService
                    .createNewUser(email, fullName, password)
                    .then((res) => {
                        toast.success('Student Created Successfully!', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setFullName('');
                        setEmail('');
                        setPassword('');
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        if (err.response.status === 422) {
                            const message = err.response.data.detail[0].msg;
                            toast.error(message, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else if (err.response.status === 400) {
                            const message = err.response.data.detail;
                            toast.error(message, {
                                position: 'top-right',
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        setIsLoading(false);
                    });
            } else {
                toast.error("Fields can't be blank. Please fill out all of the fields.", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch {
            console.log('Failed creating new student. Please try again.');
            console.log('Error posting new student data.');
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {localStorage.getItem('isLogged') === 'true' ? (
                <div id="createStudent" className="mt-5">
                    <h1>
                        <strong>Add New Student</strong>
                    </h1>
                    <p className="subtext-color mt-3">
                        Fill in the fields to add a <strong>new student</strong> to the database.
                    </p>

                    <form className="card">
                        <>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="basic-url">Full Name</label>
                                        <div className="input-group mb-3">
                                            <input
                                                value={fullName}
                                                onChange={fullNameChangeHandler}
                                                type="name"
                                                className="form-control"
                                                placeholder="Student Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mt-2">
                                        <label htmlFor="basic-url">Email Address</label>
                                        <div className="input-group mb-3">
                                            <input
                                                value={email}
                                                onChange={emailChangeHandler}
                                                type="email"
                                                className="form-control"
                                                placeholder="student@example.com"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mt-2">
                                        <label htmlFor="basic-url">Password</label>
                                        <div className="input-group mb-3">
                                            <input
                                                value={password}
                                                onChange={passwordChangeHandler}
                                                type="password"
                                                className="form-control"
                                                placeholder="•••••••••••"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="basic-url">LMS Role</label>
                                <div className="input-group mb-3">
                                    <input
                                        value="student"
                                        type="text"
                                        className="form-control disabled"
                                        placeholder="student"
                                        disabled
                                    />
                                </div>
                            </div>
                        </>

                        <div className="d-flex justify-content-between mt-1">
                            <Link to="/students" className="btn btn-secondary btn-md">
                                Back to Students
                            </Link>
                            <button
                                type="submit"
                                onClick={addStudent}
                                disabled={isLoading}
                                className="btn btn-primary btn-md"
                            >
                                <span>
                                    {isLoading ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <span>
                                            <Icon.Plus />
                                        </span>
                                    )}
                                    <span className="ml-2">Add Student</span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Program;
