import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// API
import apiHeaders from 'services/apiHeader';
import apiurls from 'services/apiRoutes';

// Assets
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';
import QuizPassedIcon from 'assets/Icons/quiz-passed.svg';
import QuizFailedIcon from 'assets/Icons/quiz-failed.svg';

const Title = styled.h1``;

const End = ({ pts, countOptions, articleName }) => {
    const backToLesson = () => window.history.back();

    const [result, setResult] = useState();

    useEffect(() => {
        if (pts >= countOptions / 2) {
            setResult('Passed');
        } else {
            setResult('Failed');
        }
        const userData = {
            userId: `${localStorage.getItem('UserID')}`,
            userEmail: `${localStorage.getItem('email')}`,
            userGrades: {
                articleName: `${articleName}`,
                options: `${countOptions}`,
                score: `${pts}`,
                result: `${result}`,
            },
        };
        console.log(articleName);

        if (result !== undefined) {
            axios
                .post(apiurls.grades, userData, { headers: apiHeaders })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [result]);

    return (
        <div id="LMS">
            <div id="quiz-end" className="mt-5">
                <Title>Quiz Results</Title>
                <p className="subtext-color mt-3">
                    Your quiz results are available in
                    <i>"Grades"</i>.
                </p>
                <div id="quiz-results-card" className="row">
                    <div className="lms-cards d-flex p-2 mt-4">
                        {' '}
                        {/* col-sm-12 col-md-4 col-lg-4 mt-4 */}
                        <div className="card">
                            {pts >= countOptions / 2 ? (
                                <span className="badge badge-success uppercase fs-12 fw-regular">Passed</span>
                            ) : (
                                <span className="badge badge-danger uppercase fs-12 fw-regular">Failed</span>
                            )}
                            {/* <img className="card-img-top p-1" src={HomeImg1} alt="Card image cap" /> */}
                            <div className="card-body mt-2">
                                <h4 className="card-title">{articleName}</h4>
                                <p className="subtext-color mt-5">
                                    {pts >= countOptions / 2 ? (
                                        <div className="end-result">
                                            <h5>Congratulations, </h5>{' '}
                                            {/* <strong>{localStorage.getItem('full_name')},</strong> */}
                                            <img src={QuizPassedIcon} alt="" className="quiz-passed-icon img-fluid" />
                                            You <strong>passed</strong> the quiz with a score result of <br />{' '}
                                            <strong>
                                                {pts} out of{countOptions} points.
                                            </strong>
                                        </div>
                                    ) : (
                                        <div className="end-result">
                                            <h5>We're sorry to hear, </h5>{' '}
                                            {/* <strong>{localStorage.getItem('full_name')},</strong> */}
                                            <img src={QuizFailedIcon} alt="" className="quiz-passed-icon img-fluid" />
                                            You <strong>failed</strong> the quiz with a score result of <br />{' '}
                                            <strong>
                                                {pts} out of{countOptions} points.
                                            </strong>
                                        </div>
                                    )}
                                </p>
                                <Link
                                    to={{
                                        pathname: `/grades`,
                                    }}
                                    className="btn btn-primary w-100 mt-3"
                                >
                                    Grades <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>
    );
};

export default End;
