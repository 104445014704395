import React from 'react';

// Components
import { Navigation, Footer } from 'components';

const Error404 = () => (
    <div>
        <Navigation />
        <div id="Error" className="container">
            Error 404. Page not found.
        </div>
        <Footer />
    </div>
);

export default Error404;
