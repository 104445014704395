import 'd3-transition';
import { select } from 'd3-selection';
import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import { convertValueToHex } from 'utils/convertValueToHex';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

let textArray = [];

function getCallback(callback) {
    return function(word, event) {
        const element = event.target;
        const text = select(element);
        text.transition()
            .attr('background', 'white')
            .attr('text-decoration', 'none');
    };
}

const getTooltip = (keyword) => {
    if (textArray.length !== 0) {
        const filtered = textArray.filter((item) => item.name.includes(keyword))[0].name;
        return filtered;
    }
    return '';
};

const options = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [10, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 0,
};

const callbacks = {
    getWordColor: (word) => convertValueToHex(word.value),
    getWordTooltip: (word) => getTooltip(word.text),
    onWordClick: getCallback('onWordClick'),
    onWordMouseOut: getCallback('onWordMouseOut'),
    onWordMouseOver: getCallback('onWordMouseOver'),
};

const WordCloud = (props) => {
    const { arrayStrengthWeakness } = props;
    textArray = arrayStrengthWeakness;
    const words = arrayStrengthWeakness.map((element) => ({
        text: element.name.substr(0, element.name.lastIndexOf('.')),
        value: 100 - element.value,
    }));
    return (
        <div style={{ height: 400, width: '100%' }}>
            <ReactWordcloud options={options} callbacks={callbacks} words={words} />
        </div>
    );
};

export default WordCloud;
