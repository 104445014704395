// const apiHeaders = {
//     'Content-type': 'application/json',
//     'Authorization': 'Bearer enginex'
// }

const apiHeaders = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
};

export default apiHeaders;
