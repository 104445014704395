import '../Modals.scss';

// Components
import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const GenerateQuestionModal = (props) => {
    const { highlight, handleChangeHighlight, handleGenerate, isGenerating } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modalContainer"
        >
            <Modal.Body>
                <div id="question-field" className="input-field">
                    <label htmlFor="question">Highlight</label>
                    <textarea
                        placeholder="Your question"
                        value={highlight}
                        disabled={!!isGenerating}
                        onChange={handleChangeHighlight}
                        name="question"
                        id="input-question"
                        className="card-input"
                        cols="10"
                        rows="3"
                    />
                </div>
                <Button
                    className="p-10"
                    className="btn btn-primary generateButton"
                    disabled={!!isGenerating}
                    onClick={handleGenerate}
                >
                    {isGenerating ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        <span>Generate Questions</span>
                    )}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default GenerateQuestionModal;
