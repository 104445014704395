// Modules
import React from 'react';
// HOC
import { Wrapper } from 'hoc';
// Components
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RouteWithLayout, Layout } from 'components';

import {
    // Client Components
    Homepage as HomepageView,
    Login as LoginView,
    Register as RegisterView,

    // Student Dashboard Components
    Home as HomeView,
    LMS as LMSView,
    Course as CourseView,
    Content as ContentView,
    Quiz as QuizView,
    DirectQuiz as DirectQuizView,
    Quizzes as QuizzesView,
    Grades as GradesView,
    MyAccount as MyAccountView,

    // Admin/Instructor Dashboard Components
    App as AppView,
    Students as StudentsView,
    Management as ManagementView,
    Enrollments as EnrollmentsView,
    CourseLearners as CourseLearnersView,
    Leadership as LeadershipView,
    Analytics as AnalyticsView,
    AllQuestions as AllQuestionsView,
    Error404 as Error404View,
    CreateStudent as CreateStudentView,
    CoursesManagement as CoursesManagementView,
} from './views';

const Router = () => (
    <Wrapper>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={HomepageView} />
                <Route path="/signup" component={RegisterView} />
                <Route path="/login" component={LoginView} />
                <Route path="/allQuestions" component={AllQuestionsView} />
                {/* LMS/Student Routes */}
                <RouteWithLayout
                    component={HomeView}
                    exact
                    layout={Layout}
                    path="/home"
                />
                <RouteWithLayout
                    component={LMSView}
                    exact
                    layout={Layout}
                    path="/lms"
                />
                <RouteWithLayout
                    component={CourseView}
                    exact
                    layout={Layout}
                    path="/lms/:courseId"
                />
                <RouteWithLayout
                    component={ContentView}
                    exact
                    layout={Layout}
                    path="/lms/:courseId/:lessonId"
                />
                {/* CVOT Quiz */}
                <RouteWithLayout
                    component={QuizView}
                    exact
                    layout={Layout}
                    path="/lms/:courseId/:lessonId/quiz"
                />
                {/* 2M0X3 Quiz */}
                <RouteWithLayout
                    component={DirectQuizView}
                    exact
                    layout={Layout}
                    path="/quiz"
                />
                <RouteWithLayout
                    component={QuizzesView}
                    exact
                    layout={Layout}
                    path="/quizzes"
                />
                <RouteWithLayout
                    component={GradesView}
                    exact
                    layout={Layout}
                    path="/grades"
                />
                <RouteWithLayout
                    component={MyAccountView}
                    exact
                    layout={Layout}
                    path="/myaccount"
                />
                {/* Admin/Instructor Routes */}
                <RouteWithLayout
                    component={AppView}
                    exact
                    layout={Layout}
                    path="/app"
                />
                <RouteWithLayout
                    component={StudentsView}
                    exact
                    layout={Layout}
                    path="/students"
                />
                <RouteWithLayout
                    component={CreateStudentView}
                    exact
                    layout={Layout}
                    path="/createStudent"
                />
                <RouteWithLayout
                    component={CoursesManagementView}
                    exact
                    layout={Layout}
                    path="/courses"
                />
                <RouteWithLayout
                    component={EnrollmentsView}
                    exact
                    layout={Layout}
                    path="/enrollments"
                />
                <RouteWithLayout
                    component={CourseLearnersView}
                    exact
                    layout={Layout}
                    path="/enrollments/course/learners/:id"
                />
                <RouteWithLayout
                    component={ManagementView}
                    exact
                    layout={Layout}
                    path="/management"
                />
                <RouteWithLayout
                    component={LeadershipView}
                    exact
                    layout={Layout}
                    path="/leadership"
                />
                <RouteWithLayout
                    component={AnalyticsView}
                    exact
                    layout={Layout}
                    path="/analytics/learners/:id"
                />
                <Route component={Error404View} />
            </Switch>
        </BrowserRouter>
    </Wrapper>
);

export default Router;
