import React from 'react';

// Assets
import DashboardDecorLeft from 'assets/s-dashboard-d-left-min.png';
import DashboardDecorRight from 'assets/s-dashboard-d-right-min.png';

// Styles
import './LmsWrapper.scss';

const Wrapper = (props) => (
    <div id="LMS-Wrapper">
        {props.children}

        {/* SVG Decors */}
        <img src={DashboardDecorLeft} alt="Hero Decor SVG" className="s-dahsboard-d-left img-fluid" />
        <img src={DashboardDecorRight} alt="Hero Decor SVG" className="s-dahsboard-d-right img-fluid" />
    </div>
);

export default Wrapper;
