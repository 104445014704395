import React, { useState, useEffect } from 'react';

// Components
import { Redirect } from 'react-router-dom';

// Styles
import './Students.scss';

// APIs
import userService from 'services/user.service';

const Program = () => {
    // State
    const [studentsGrades, setStudentsGrades] = useState([]);
    const [countGrades, setCountGrades] = useState(1);
    const [mounted, setMounted] = useState(false);

    // Grades Handler

    useEffect(() => {
        userService.getAllQuizresults()
            .then(response => {
                setCountGrades(response.length);
                setStudentsGrades(response);
                setMounted(true);
            })
    }, []);
    // End of Grades Handler

    // Load All/Slice State
    const [sliceNext, setSliceNext] = useState(10);

    useEffect(() => {
        if (countGrades < 10) {
            setSliceNext(countGrades);
        } else {
            setSliceNext(10);
        }
    }, [countGrades]);

    const handleGradesLoad = () => {
        if (sliceNext < countGrades) {
            setSliceNext(sliceNext + countGrades - sliceNext);
        }
    };

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                mounted ? (
                    <div id="Students" className="mt-5">
                        <h1>
                            <strong>Students Grades</strong>
                        </h1>
                        <p className="subtext-color mt-3">
                            All <strong>students</strong> grades data.
                        </p>
                        <div className="tableFixHead mt-5">
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Student Email</th>
                                        <th scope="col">Articles</th>
                                        <th scope="col">Questions</th>
                                        <th scope="col">Scores</th>
                                        <th scope="col">Results</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentsGrades.slice(0, sliceNext).map((studentGrade, index) => (
                                        <tr key={index}>
                                            <td>{studentGrade.student.email}</td>
                                            <td>{studentGrade.lesson.title}</td>
                                            <td>{studentGrade.lesson.quizitems_num}</td>
                                            <td>{studentGrade.score}</td>
                                            <td>
                                                {studentGrade.result === true ? (
                                                    <span>Passed</span>
                                                ) : (
                                                    <strong>Failed</strong>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            {countGrades >= 10 ? (
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-center"
                                        onClick={handleGradesLoad}
                                    >
                                        Load All
                                    </button>
                                </div>
                            ) : null}
                            <div className="align-self-center">
                                <span className="subtext-color">
                                    <strong>
                                        {sliceNext} /{countGrades}
                                    </strong>{' '}
                                    quiz results.
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '100px',
                            transform: 'translate(-50%, 0%)',
                        }}
                    >
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Program;
