import React, { useState, useEffect } from 'react';

// HOC
import { LmsWrapper } from 'hoc';

// Components
import { StudentSidebar, Navbar } from 'components';
import { Link, Redirect } from 'react-router-dom';

// Assets
import QuizItemIcon from 'assets/Icons/quiz-item.svg';
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles
import '../LMS.scss';

// API
import userService from 'services/user.service';

const Course = ({ match }) => {
    const [courseTitle, setCourseTitle] = useState('');
    const [lessons, setLessons] = useState([]);
    const [mounted, setMounted] = useState(false);

    const { courseId } = match.params;
    useEffect(() => {
        setMounted(false);
        userService.getCourseById(courseId).then((response) => {
            setCourseTitle(response.title);
            setLessons(response.lessons);
            setMounted(true);
        });
    }, []);

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                mounted ? (
                    <div id="LMS" className="mt-5">
                        <div>
                            <div className="breadcrumb">
                                <Link to="/lms">Courses</Link>
                                <span>&nbsp; / &nbsp;</span>
                                {courseTitle}
                            </div>

                            <h1>{courseTitle}</h1>
                            <p className="subtext-color mt-3">
                                All articles for <strong>{courseTitle}</strong> course.
                            </p>
                        </div>

                        <div id="course" className="row">
                            {lessons.map((lesson) => (
                                <div key={lesson.id} className="lms-cards d-flex p-2 mt-4">
                                    {' '}
                                    <div className="card">
                                        <div className="card-body mt-2">
                                            <h4 className="card-title">{lesson.title}</h4>
                                            <p className="subtext-color mt-5">
                                                <img src={QuizItemIcon} alt="" className="quiz-item-icon img-fluid" />
                                            </p>
                                            <p className="subtext-color mt-5">
                                                Duration: <strong>max {lesson.duration_max} seconds.</strong>
                                            </p>
                                            <p className="subtext-color mt-4">{lesson.description}</p>

                                            <Link
                                                to={{
                                                    pathname: `${match.url}/${lesson.id}`,
                                                }}
                                                className="btn btn-primary w-100 mt-3"
                                            >
                                                Start Lesson{' '}
                                                <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div style={{ position: 'absolute', left: '50%', top: '100px', transform: 'translate(-50%, 0%)' }}>
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Course;
