import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { CSVLink } from 'react-csv';
import axios from 'axios';
import apiurls from 'services/apiRoutes';
import apiHeaders from 'services/apiHeader';
import { round2DecimalPlaces } from 'utils/round2DecimalPlaces';
import { FiDownload } from 'react-icons/fi';

// Styles
import './Analytics.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Analytics = (props) => {
    const learnerId = props.match.params.id;
    const query = useQuery();

    const csvHeader = [
        { label: 'Time Stamp', key: 'time_stamp' },
        { label: 'Sess. ID', key: 'sess_id' },
        { label: 'CDC', key: 'cdc' },
        { label: 'Section', key: 'section' },
        { label: 'User', key: 'user' },
        { label: 'Q #', key: 'q_sharp' },
        { label: 'Q Seq #', key: 'q_seq_sharp' },
        { label: 'Question', key: 'question' },
        { label: 'Correct Answer', key: 'correct_answer' },
        { label: 'User First Answer', key: 'user_first_answer' },
        { label: 'Attempts', key: 'attempts' },
        { label: 'Time (s)', key: 'times' },
        { label: 'Points', key: 'points' },
        { label: 'Choices Shown (incl. Answer) in OrderPoints', key: 'choices' },
        { label: 'User Select Sequence', key: 'user_select_seq' },
    ];

    const [name, setName] = useState('');
    const [studentsData, setStudentsData] = useState([]);
    const [countStudents, setCountStudents] = useState(0);
    const [sliceNext, setSliceNext] = useState(10);
    const dayStart = query.get('start');
    const dayEnd = query.get('end');

    useEffect(() => {
        axios.get(`${apiurls.learnerQuestionsData}?userId=${learnerId}`, { headers: apiHeaders }).then((res) => {
            const filterByDay = res.data.filter(
                (item) => Number(item.time_stamp.charAt(4)) >= dayStart && Number(item.time_stamp.charAt(4)) <= dayEnd
            );
            setStudentsData(
                filterByDay.map((element) => ({
                    ...element,
                }))
            );
            setCountStudents(filterByDay.length);
        });

        axios.get(`${apiurls.users}${learnerId}`, { headers: apiHeaders }).then((res) => {
            setName(res.data.full_name);
        });
    }, []);

    useEffect(() => {
        if (countStudents < 10) {
            setSliceNext(countStudents);
        } else {
            setSliceNext(10);
        }
    }, [countStudents]);

    const handleGradesLoad = () => {
        if (sliceNext < countStudents) {
            setSliceNext(sliceNext + countStudents - sliceNext);
        }
    };

    return (
        <>
            <div id="Students" className="mt-5">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                        <h1>
                            <strong>{name}</strong>
                        </h1>
                        <p className="subtext-color mt-3">
                            <strong>Learner Analytics</strong>
                        </p>
                    </div>
                    <CSVLink
                        className="text-decoration-none"
                        filename={`${name}-analytics-file.csv`}
                        data={studentsData}
                        headers={csvHeader}
                    >
                        <div className="btn btn-primary w-100 d-flex align-items-center">
                            <span className="mr-2">Download CSV</span>
                            <FiDownload />
                        </div>
                    </CSVLink>
                </div>
                <div className="tableFixHead mt-5">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Time Stamp</th>
                                <th scope="col">Sess. ID</th>
                                <th style={{ minWidth: '350px' }} scope="col">
                                    CDC
                                </th>
                                <th style={{ minWidth: '250px' }} scope="col">
                                    Section
                                </th>
                                <th scope="col">User</th>
                                <th scope="col">Q #</th>
                                <th scope="col">Q Seq #</th>
                                <th style={{ minWidth: '300px' }} scope="col">
                                    Question
                                </th>
                                <th style={{ minWidth: '250px' }} scope="col">
                                    Correct Answer
                                </th>
                                <th scope="col">User First Answer</th>
                                <th scope="col">Attempts</th>
                                <th scope="col">Time (s)</th>
                                <th scope="col">Points</th>
                                <th style={{ minWidth: '350px' }} scope="col">
                                    Choices Shown (incl. Answer) in OrderPoints
                                </th>
                                <th style={{ minWidth: '350px' }} scope="col">
                                    User Select Sequence
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentsData.slice(0, sliceNext).map((studentData, index) => (
                                <tr key={index}>
                                    <td>{studentData.time_stamp}</td>
                                    <td>{studentData.sess_id}</td>
                                    <td>{studentData.cdc}</td>
                                    <td>{studentData.section}</td>
                                    <td>{studentData.user}</td>
                                    <td>{studentData.q_sharp}</td>
                                    <td>{studentData.q_seq_sharp}</td>
                                    <td>{studentData.question}</td>
                                    <td>{studentData.correct_answer}</td>
                                    <td>{studentData.user_first_answer}</td>
                                    <td>{studentData.attempts}</td>
                                    <td>{round2DecimalPlaces(studentData.times)}</td>
                                    <td>{round2DecimalPlaces(studentData.points)}</td>
                                    <td>{studentData.choices}</td>
                                    <td>{studentData.user_select_seq}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-between mt-4">
                    {countStudents >= 10 ? (
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary btn-center"
                                onClick={handleGradesLoad}
                            >
                                Load All
                            </button>
                        </div>
                    ) : null}
                    <div className="align-self-center">
                        <span className="subtext-color">
                            <strong>
                                {sliceNext} /{countStudents}
                            </strong>{' '}
                            Queston Results
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Analytics;
