import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

// Components
import { GlobalContext } from 'stores/globalStore';

// Assets
import Logo from 'assets/learneckeLogo.png';

// Icons
import HomeIcon from 'assets/Icons/home.svg';
import StudentsIcon from 'assets/Icons/students.svg';
import StudentEnrollmentIcon from 'assets/Icons/studentEnrollment.svg';
import CoursesIcon from 'assets/Icons/courses.svg';
import QuizzesIcon from 'assets/Icons/quizzes.svg';
import LessonsIcon from 'assets/Icons/lessons.svg';
import ArticlesIcon from 'assets/Icons/articles.svg';
import MyAccountIcon from 'assets/Icons/myaccount.svg';
import LogoutIcon from 'assets/Icons/logout.svg';
import LeadershipIcon from 'assets/Icons/leadership-view-icon.png';
import { BsPersonCheck } from 'react-icons/bs';

// Styles
import './AdminSidebar.scss';

const StudentSidebar = () => {
    const { store, dispatch } = useContext(GlobalContext);
    const { showSidebar } = store;

    const [fullName, setFullName] = useState('');
    const [overlayWidth, setOverlayWidth] = useState(-325);

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        setFullName(localStorage.getItem('full_name'));
    }, []);

    useEffect(() => {
        if (showSidebar) {
            setOverlayWidth(0);
        } else {
            setOverlayWidth(-325);
        }
    }, [showSidebar]);

    const Logout = () => {
        localStorage.clear();
    };

    // Sidebar Toggler
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 1024) {
            setIsToggled(true);
            // console.log('<= 1024px')
        } else {
            setIsToggled(false);
            // console.log('> 1024px')
        }
    }, []);

    const handleClickLink = () => {
        dispatch({
            type: 'updateSidebar',
            showSidebar: false,
        });
    }

    const closeOverlay = () => {
        dispatch({
            type: 'updateSidebar',
            showSidebar: false,
        });
    };

    return (
        <div id="SidebarWrapper">
            <div onClick={closeOverlay} className={showSidebar ? 'menuBack' : 'showDisable'} />

            {/* Sidebar */}
            <div id="admin-sidebar" className="desktop-sidebar">
                <Link to="/home" className="pl-4 pr-4">
                    <img src={Logo} alt="Logo" className="img-fluid" />
                </Link>

                <ul className="mt-5">
                    <Link className="sidebar-nav-link" to="/app">
                        <li className={pathname.includes('app') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={HomeIcon} alt="" className="img-fluid sidebar-icon" /> <span>Home</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/students">
                        <li className={pathname.includes('students') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={StudentsIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>Students Grades</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/management">
                        <li className={pathname.includes('management') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <BsPersonCheck size="24px" />
                                <span>Training Management</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/leadership">
                        <li className={pathname.includes('leadership') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img
                                    src={LeadershipIcon}
                                    style={{ width: '24px' }}
                                    alt=""
                                    className="img-fluid sidebar-icon"
                                />
                                <span>Leadership</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/enrollments">
                        <li className={pathname.includes('enrollments') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={StudentEnrollmentIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>Enrollments</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/courses">
                        <li className={pathname.includes('courses') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Courses</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={LessonsIcon} alt="" className="img-fluid sidebar-icon" /> <span>Lessons</span>
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={ArticlesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Articles</span>
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={QuizzesIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                            <span>Quizzes (AI)</span>
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/myaccount">
                        <li className={pathname.includes('myaccount') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={MyAccountIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>My Account</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                </ul>

                <div className="sidebar-bottom">
                    <ul>
                        <div className="divider" />
                        <Link className="sidebar-nav-link" onClick={Logout} to="/login">
                            <li>
                                <img src={LogoutIcon} alt="" className="img-fluid" /> <span>Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>

            <div id="admin-sidebar" style={{ marginLeft: `${overlayWidth}px` }} className="mobile-sidebar">
                <Link to="/home" className="pl-4 pr-4" onClick={handleClickLink}>
                    <img src={Logo} alt="Logo" className="img-fluid" />
                </Link>

                <ul className="mt-5">
                    <Link className="sidebar-nav-link" to="/app" onClick={handleClickLink}>
                        <li className={pathname.includes('app') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={HomeIcon} alt="" className="img-fluid sidebar-icon" /> <span>Home</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/students" onClick={handleClickLink}>
                        <li className={pathname.includes('students') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={StudentsIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>Students Grades</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/management" onClick={handleClickLink}>
                        <li className={pathname.includes('management') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <BsPersonCheck size="24px" />
                                <span>Training Management</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/leadership" onClick={handleClickLink}>
                        <li className={pathname.includes('leadership') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img
                                    src={LeadershipIcon}
                                    style={{ width: '24px' }}
                                    alt=""
                                    className="img-fluid sidebar-icon"
                                />
                                <span>Leadership</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link" to="/enrollments" onClick={handleClickLink}>
                        <li className={pathname.includes('enrollments') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={StudentEnrollmentIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>Enrollments</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/courses" onClick={handleClickLink}>
                        <li className={pathname.includes('courses') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Courses</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={LessonsIcon} alt="" className="img-fluid sidebar-icon" /> <span>Lessons</span>
                        </li>
                    </Link>
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={ArticlesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Articles</span>
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link disabled" disabled to="/app">
                        <li className="pl-4 pr-4">
                            <img src={QuizzesIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                            <span>Quizzes (AI)</span>
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/myaccount" onClick={handleClickLink}>
                        <li className={pathname.includes('myaccount') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={MyAccountIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>My Account</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                </ul>

                <div className="sidebar-bottom">
                    <ul>
                        <div className="divider" />
                        <Link className="sidebar-nav-link" onClick={Logout} to="/login">
                            <li>
                                <img src={LogoutIcon} alt="" className="img-fluid" /> <span>Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
            {/* End of Sidebar */}
        </div>
    );
};

export default StudentSidebar;
