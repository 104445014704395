import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import userService from 'services/user.service';
import End from './End';

// API

const LoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 99999999 !important;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalLoader = styled.div`
    width: 10px;
    height: 10px;
    padding: 1rem;
    background: #7754f8;
    border-radius: 50%;
    animation: 1s Loader infinite;
    animation-direction: alternate;
`;

const ModalLoaderIcon = styled.div`
    width: 7px;
    height: 7px;
    padding: 1rem;
    background: #7754f8;
    border-radius: 50%;
    animation: 1s LoaderIN infinite;
    animation-direction: alternate;
`;

const QuizWindow = styled.div`
    text-align: left;
    // font-size: clamp(20px, 2.5vw, 24px);
`;

const Options = styled.div`
    @media screen and (min-width: 1180px) {
        width: 100%;
    }

    & .option-container {
        display: flex;
    }
`;

const Option = styled.button`
    border: 1px solid transparent;
    padding: 15px 15px;
    text-decoration: none;
    text-align: left;
    color: var(--text-color);
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
    font-size: 18px;

    &:hover {
        background: #f1f1f1;
        // color: var(--white-color);
        // background-color: var(--secondary-color);
        // border: 1px solid var(--secondary-color);
    }
`;

const Question = styled.h3``;

const QA = ({ lessonId }) => {
    const [quiz, setQuiz] = useState([]);
    const [number, setNumber] = useState(0);
    const [countOptions, setCountOptions] = useState();
    const [pts, setPts] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [articleName, setArticleName] = useState('');

    // Mutliple Choice Answer Handlers
    const pickAnswer = (e) => {
        const userAnswer = e.target.innerText;

        if (quiz[number].answer === userAnswer) {
            setPts(pts + 1);
        }
        setNumber(number + 1);

        // Resetting Question Reference
        setShowRef(false);
    };

    // Short Answer Handlers
    let userAnswer = '';
    const handleShortAnswer = (e) => {
        userAnswer = e.target.value;
    };
    const submitShortAnswer = () => {
        if (quiz[number].answer === userAnswer) setPts(pts + 1);
        setNumber(number + 1);
    };

    useEffect(() => {
        setMounted(false);
        userService.getLessonById(lessonId).then((response) => {
            setQuiz(response.quizitems);
            setCountOptions(response.quizitems.length);
            setArticleName(response.title);
            setMounted(true);
        });
    }, []);

    // Show Reference Handler
    const [showRef, setShowRef] = useState(false);

    const handleReference = () => {
        setShowRef(!showRef);
    };

    return (
        <>
            {mounted ? (
                <QuizWindow className="qa">
                    <div className="mt-5">
                        {quiz[number] ? (
                            <>
                                <h1>
                                    <strong>Quiz</strong> {articleName}
                                </h1>
                                <p className="subtext-color mt-3">
                                    Upon quiz completion you'll be able to see the quiz results in <i>"Grades"</i>.
                                </p>

                                <div className="qa-wrapper mt-5">
                                    <p className="subtext-color">
                                        Question {number + 1} /{countOptions}
                                    </p>
                                    <Question dangerouslySetInnerHTML={{ __html: quiz[number].question }} />
                                    {quiz[number].type === 'multiChoice' ? (
                                        <Options id="quiz-options" className="mt-5 mb-5">
                                            {quiz[number].choices.map((item, index) => (
                                                <div className="option-container">
                                                    <span id="option-index">{index + 1}</span>
                                                    <Option
                                                        key={index}
                                                        dangerouslySetInnerHTML={{ __html: item }}
                                                        onClick={pickAnswer}
                                                    />
                                                </div>
                                            ))}
                                            {[quiz[number]].map((item, index) => (
                                                <div key={index} className="mt-5">
                                                    <div className="divider" />
                                                    <h5 className="mt-5">Don't know the answer? Learn it.</h5>
                                                    <div className="form-check no-user-select mt-4">
                                                        <input
                                                            checked={!!showRef}
                                                            className="form-check-input pointer"
                                                            type="checkbox"
                                                            id="showReference"
                                                            onClick={handleReference}
                                                        />
                                                        <label
                                                            className="form-check-label pointer"
                                                            htmlFor="showReference"
                                                        >
                                                            Show Reference
                                                        </label>
                                                    </div>
                                                    {showRef ? (
                                                        <div>
                                                            <p className="subtext-color mt-4">{item.ref}</p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </Options>
                                    ) : null}
                                    {quiz[number].type === 'shortAnswer' ? (
                                        <Options id="quiz-options" className="mt-5">
                                            {quiz[number].options.map((item, index) => (
                                                <div className="form-group mt-4">
                                                    <input
                                                        className="form-control"
                                                        id="exampleInputPassword1"
                                                        onChange={handleShortAnswer}
                                                        type="text"
                                                        placeholder={`Correct: ${item}`}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary mt-5"
                                                        onClick={submitShortAnswer}
                                                    >
                                                        Submit Answer
                                                    </button>
                                                </div>
                                            ))}
                                        </Options>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>
                    {number === countOptions ? (
                        <End pts={pts} countOptions={countOptions} articleName={articleName} lessonId={lessonId} />
                    ) : null}
                </QuizWindow>
            ) : (
                <div style={{ position: 'absolute', left: '50%', top: '100px', transform: 'translate(-50%, 0%)' }}>
                    <div className="spinner-border text-muted" />
                </div>
            )}
        </>
    );
};

export default QA;
