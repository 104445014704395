import React from 'react';

// Styles
import './CoursesManagement.scss';

const CoursesManagement = () => (
    <>
    </>
);

export default CoursesManagement;
