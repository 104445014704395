import { createContext } from 'react';

export const initialState = {
    users: [],
    units: [],
    showSidebar: false,
    userInfo: {}
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'reset':
            return initialState;

        case 'updateUsers':
            return {
                ...state,
                users: action.users,
            };

        case 'updateUnits':
            return {
                ...state,
                units: action.units,
            };

        case 'updateSidebar':
            return {
                ...state,
                showSidebar: action.showSidebar,
            };

        case 'updateUserInfo':
            return {
                ...state,
                userInfo: action.userInfo,
            }

        default:
            return state;
    }
};

export const GlobalContext = createContext(initialState);
