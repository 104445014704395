import React from 'react';
import { AdminSidebar, StudentSidebar } from 'components';

const Sidebar = () => {
  return (
    <>
      {
        localStorage.getItem('lms_role') !== 'student' ? (
          <AdminSidebar />
        ) : (
          <StudentSidebar />
        )
      }
    </>
  )
}

export default Sidebar;