import React, { useEffect, useState, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GlobalContext } from 'stores/globalStore';
import { options, managementSeries, leadershipSeries } from './config';

const Heatmap = (props) => {
    const { pageType, dayRange } = props;
    const [optionState, setOptionState] = useState(options);
    const [seriesState, setSeriesState] = useState([]);
    const [propsSeries, setPropsSeries] = useState([]);
    const [heatmapType, setHeatmapType] = useState('');
    const [userLength, setUserLength] = useState(0);
    const [unitLength, setUnitLength] = useState(0);

    const { store, dispatch } = useContext(GlobalContext);
    const { users } = store;
    const { units } = store;

    const getRandomArbitrary = () => {
        const min = 2.99;
        const max = 5.0;
        const data = [
            {
                x: '2M053 - 006. Intercontinental ballistic missile wing',
                y: Math.round((Math.random() * (max - min) + min) * 100) / 100,
            },
            {
                x: '2M053 - 016. Nuclear certified equipment',
                y: Math.round((Math.random() * (max - min) + min) * 100) / 100,
            },
            {
                x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview',
                y: Math.round((Math.random() * (max - min) + min) * 100) / 100,
            },
            {
                x: 'CDL - 028. Radio frequency interference gasket inspections',
                y: Math.round((Math.random() * (max - min) + min) * 100) / 100,
            },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: Math.round((Math.random() * (max - min) + min) * 100) / 100 },
        ];
        return data;
    };

    useEffect(() => {
        if (pageType === 'management') {
            setSeriesState(managementSeries);
            setHeatmapType('management');
        } else if (pageType === 'leadership') {
            setSeriesState(leadershipSeries);
            setHeatmapType('leadership');
        }
    }, []);

    useEffect(() => {
        if (heatmapType === 'management') {
            const checkedUsers = users.filter((user) => user.check);
            if (checkedUsers.length !== userLength) {
                setUserLength(checkedUsers.length);
                const tempSeries = [];
                seriesState.forEach((element) => {
                    checkedUsers.forEach((userElement) => {
                        if (element.name === userElement.name) {
                            tempSeries.push(element);
                        }
                    });
                });

                setPropsSeries(tempSeries);
            }
        } else if (heatmapType === 'leadership') {
            const checkedUnits = units.filter((unit) => unit.check);
            if (checkedUnits.length !== unitLength) {
                setUnitLength(checkedUnits.length);
                const tempSeries = [];
                seriesState.forEach((element) => {
                    checkedUnits.forEach((unitElement) => {
                        if (element.name === unitElement.unit) {
                            tempSeries.push(element);
                        }
                    });
                });
                setPropsSeries(tempSeries);
            }
        }
    }, [users, units, seriesState]);

    useEffect(() => {
        const temp = [];
        const test = [...propsSeries];
        test.forEach((element) => {
            const tempElement = {
                name: element.name,
                data: getRandomArbitrary(),
            };
            temp.push(tempElement);
        });
        setPropsSeries(temp);
    }, [dayRange]);
    return propsSeries.length === 0 ? (
        <div />
    ) : (
        <ReactApexChart options={optionState} series={propsSeries} type="heatmap" width="100%" height={600} />
    );
};

export default Heatmap;
