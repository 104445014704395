import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { EnrollLearnersModal } from 'components';
import { Button } from 'react-bootstrap';

// Styles
import './CourseLearners.scss';

// APIs
import userService from 'services/user.service';

const CourseLeaners = ({ match }) => {
    const { id } = match.params;

    const [courseData, setCourseData] = useState({});
    const [learners, setLearners] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [enrollLearnersModalShow, setEnrollLearnersModalShow] = useState(false);
    const [students, setStudents] = useState([]);
    const [loadingLearners, setLoadingLearners] = useState(false);
    const [isEnrolling, setIsEnrolling] = useState(false);

    useEffect(() => {
        userService.getCourseById(id).then((response) => {
            setCourseData(response);
            setLearners(response.users_assigned);
            setMounted(true);
        });
    }, []);

    const handleAddLearner = () => {
        setLoadingLearners(true);
        setEnrollLearnersModalShow(true);
        userService.getCourseById(id).then((response) => {
            const ownedStudents = response.owner.students;
            const ownedLearners = response.users_assigned;
            let studentsArray = [...ownedStudents];
            ownedLearners.forEach((ownedLearner) => {
                studentsArray = studentsArray.filter((student) => student.id !== ownedLearner.id);
            });
            setStudents(studentsArray);
            setLoadingLearners(false);
        });
    };

    const handleRemoveLearner = (learnerId) => {
        userService.removeLearnerFromCourse(learnerId, courseData.id).then((response) => {
            setLearners(learners.filter((learner) => learner.id !== response.id));
        });
    };

    const handleHideEnrollLearners = () => {
        setEnrollLearnersModalShow(false);
    };

    const handleEnrollLearners = (enrollList) => {
        const enrollIdList = [];
        enrollList.forEach((enrollElement) => {
            enrollIdList.push(enrollElement.id);
        });
        setIsEnrolling(true);
        userService.enrollMultiLearnersToCourse(enrollIdList, courseData.id).then((response) => {
            setLearners([...learners, ...enrollList]);
            setEnrollLearnersModalShow(false);
            setIsEnrolling(false);
        });
    };

    return (
        <>
            <EnrollLearnersModal
                show={enrollLearnersModalShow}
                onHide={() => handleHideEnrollLearners()}
                students={students}
                loadingLearners={loadingLearners}
                isEnrolling={isEnrolling}
                handleEnrollLearners={handleEnrollLearners}
            />
            <div>
                {localStorage.getItem('isLogged') === 'true' ? (
                    mounted ? (
                        <div className="mt-5">
                            <h1>
                                <strong>{courseData.title}</strong>
                            </h1>
                            <p className="subtext-color mt-3">{courseData.description}</p>
                            <div className="divider" />
                            <div className="mt-3 mb-4">
                                <Button className="p-10 btn btn-primary generateButton" onClick={handleAddLearner}>
                                    Add Learners
                                </Button>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {learners.map((learner) => (
                                        <tr key={learner.id} className="course-row">
                                            <td>{learner.full_name}</td>
                                            <td className="email-col">
                                                <span>{learner.email}</span>
                                                <span
                                                    className="remove-col"
                                                    onClick={() => handleRemoveLearner(learner.id)}
                                                >
                                                    Remove
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '100px',
                                transform: 'translate(-50%, 0%)',
                            }}
                        >
                            <div className="spinner-border text-muted" />
                        </div>
                    )
                ) : (
                    <Redirect to="/login" />
                )}
            </div>
        </>
    );
};

export default CourseLeaners;
