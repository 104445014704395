import React, { useState, useEffect } from 'react';

// Components
import { Link, Redirect } from 'react-router-dom';

// Assets
import StudentsGrades from 'assets/studentsGrades.png';
import HomeImg1 from 'assets/home1.png';
import HomeImg2 from 'assets/home2.png';
import HomeImg3 from 'assets/home3.png';
import CheckmarkGreenIcon from 'assets/Icons/checkmark-circle-green.svg';
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles

// Utils

const App = () => {
    // Component State
    const [fullName, setFullName] = useState('');

    useEffect(() => {
        if (localStorage.getItem('full_name') !== 'null') {
            setFullName(localStorage.getItem('full_name'));
        }
    }, []);

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' &&
                (localStorage.getItem('lms_role') === 'system' ||
                    localStorage.getItem('lms_role') === 'superuser' ||
                    localStorage.getItem('lms_role') === 'instructor' ||
                    localStorage.getItem('lms_role') === 'admin') ? (
                <div id="LMS" className="mt-5">
                    <h1>
                        Hey <strong>{fullName}</strong>, Welcome to Learnecke
                    </h1>
                    <p className="subtext-color mt-3">Your management journey starts here.</p>

                    {localStorage.getItem('lms_role') === 'admin' ? (
                        <div id="home-cards" className="row">
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={StudentsGrades} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">Measure Student Progress</h4>
                                        <p className="subtext-color mt-3">
                                            Get to know more about your students and get a full control over their
                                            learning progress.
                                        </p>
                                        <Link to="/students" className="btn btn-primary w-100 mt-2">
                                            Students Grades{' '}
                                            <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id="home-cards" className="row">
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg1} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">1. Create a Student</h4>
                                        <p className="subtext-color mt-3">
                                            Create an account for your students and get a full control over their
                                            progress.
                                        </p>
                                        <Link to="/createStudent" className="btn btn-primary w-100 mt-2">
                                            Create a Student{' '}
                                            <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg2} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">2. Create Course Materials</h4>
                                        <p className="subtext-color mt-3">
                                            Create course materials such as courses, lessons and articles for each
                                            student.
                                        </p>
                                        <Link to="/courses" className="btn btn-primary w-100 mt-2">
                                            Create a Course{' '}
                                            <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg3} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">3. Enroll Students</h4>
                                        <p className="subtext-color mt-3">
                                            Enroll your students individually to a particular course tailored for
                                            their learning.
                                        </p>
                                        <Link to="/enrollments" className="btn btn-primary w-100 mt-2">
                                            Enroll a Student{' '}
                                            <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="lms-cards d-flex p-2 mt-4">
                                <div className="card">
                                    <img className="card-img-top p-1" src={HomeImg3} alt="Card image cap" />
                                    <div className="card-body mt-2">
                                        <h4 className="card-title">
                                            <strong>4. Generate a Quiz (AI)</strong>
                                        </h4>
                                        <p className="subtext-color mt-3">
                                            Use our artificial intelligance models to generate quizzes in a matter
                                            on seconds.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-secondary w-100 mt-2 disabled"
                                            disabled
                                        >
                                            <img
                                                src={CheckmarkGreenIcon}
                                                alt=""
                                                className="i-check-green img-fluid"
                                            />
                                            <span className="home-course-completed">Generate Quiz!</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default App;
