import React from 'react';
import Slider from 'rc-slider';
import './index.scss';

const { Range } = Slider;

const TimeSlider = ({ handleChangeTimeBar }) => {
    const marks = {
        0: <strong>Commencement</strong>,
        25: 'Day 2',
        50: 'Day 3',
        75: 'Day 4',
        100: <strong>Today</strong>,
    };

    return (
        <div className="time-bar-container">
            <Range dots step={25} marks={marks} defaultValue={[0, 100]} onAfterChange={handleChangeTimeBar} />
        </div>
    );
};

export default TimeSlider;
