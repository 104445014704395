import React, { useEffect } from 'react';

// Components
import { Link, Redirect } from 'react-router-dom';

// API

// Assets
import QuizItemIcon from 'assets/Icons/quiz-item.svg';
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles
import '../Courses/LMS.scss';

const Quizzes = () => {
    useEffect(() => { }, []);

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                <div id="LMS" className="mt-5">
                    <h1>
                        <strong>Quizzes</strong>
                    </h1>
                    <p className="subtext-color mt-3">List of all uncompleted quizzes.</p>

                    <div id="quiz-results-card" className="row">
                        <div className="lms-cards d-flex p-2 mt-4">
                            {' '}
                            <div className="card">
                                <div className="card-body mt-2">
                                    <h4 className="card-title">2M0X3 Quiz</h4>
                                    <p className="subtext-color mt-5">
                                        <img src={QuizItemIcon} alt="" className="quiz-item-icon img-fluid" />
                                    </p>
                                    <p className="subtext-color mt-5">
                                        Duration: <strong>max 45 min.</strong>
                                    </p>
                                    <p className="subtext-color mt-4">
                                        Air Force Missile and Space Facilities (2M0X3) specialize in the maintenance
                                        and repair of missile and space lift support systems.
                                    </p>

                                    <Link to="/quiz" className="btn btn-primary w-100 mt-3">
                                        Take Quiz <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Redirect to="/login" />
            )}
            {localStorage.getItem('af_student') === 'CVOT' ? <Redirect to="/home" /> : null}
        </>
    );
};

export default Quizzes;
