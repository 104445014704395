import React from 'react';

// HOC
import { Redirect } from 'react-router-dom';

// Components
import QA from './QuizComponents/QA';

const Quiz = ({ match }) => {
    const { lessonId } = match.params;

    return (
        <>
            <div id="quiz" className="quiz mt-5">
                {localStorage.getItem('isLogged') === 'true' ? (
                    <QA lessonId={lessonId} />
                ) : (
                    <Redirect to="/login" />
                )}
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default Quiz;
