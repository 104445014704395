import React, { useState, useEffect } from 'react';

// Components
import { Link, Redirect } from 'react-router-dom';

// Assets
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';
import ArrowBlackIcon from 'assets/Icons/arrowBlack.svg';

// Styles
import '../LMS.scss';

// API
import userService from 'services/user.service';

const Content = ({ match }) => {
    const { courseId, lessonId } = match.params;
    const [lessonTitle, setLessonTitle] = useState('');
    const [lessonDescription, setLessonDescription] = useState('');
    const [courseTitle, setCourseTitle] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(false);
        userService.getLessonById(lessonId).then((response) => {
            setLessonTitle(response.title);
            setLessonDescription(response.description);
            userService.getCourseById(courseId).then((response) => {
                setCourseTitle(response.title);
                setCourseDescription(response.description);
                setMounted(true);
            });
        });
    }, []);

    return (
        <>
            {localStorage.getItem('isLogged') === 'true' ? (
                mounted ? (
                    <div id="LMS" className="mt-5">
                        <div className="row">
                            <div className="Article">
                                <div className="breadcrumb">
                                    <Link to="/lms">Courses</Link>
                                    <span>&nbsp; / &nbsp;</span>
                                    <Link
                                        to={{
                                            pathname: `/lms/${courseId}`,
                                        }}
                                    >
                                        {courseTitle}
                                    </Link>
                                    <span>&nbsp; / &nbsp;</span>
                                    <p>{lessonTitle}</p>
                                </div>
                                <h1>{lessonTitle}</h1> <br />
                                <div dangerouslySetInnerHTML={{ __html: lessonDescription }} />
                                <div className="d-flex justify-content-between">
                                    <Link
                                        to={{
                                            pathname: `/lms/${courseId}`,
                                            state: {
                                                courseId: `${courseId}`,
                                                courseTitle: `${courseTitle}`,
                                                courseDescription: `${courseDescription}`,
                                            },
                                        }}
                                        className="btn btn-secondary btn-md mt-5"
                                    >
                                        <img src={ArrowBlackIcon} alt="" className="i-arrow-b-black img-fluid" />
                                        Back
                                    </Link>

                                    <Link
                                        to={{
                                            pathname: `${match.url}/quiz`,
                                            state: {
                                                articleName: `${lessonTitle}`,
                                                lessonId: `${lessonId}`,
                                            },
                                        }}
                                        className="btn btn-primary btn-md mt-5"
                                    >
                                        Take Quiz <img src={ArrowWhiteIcon} alt="" className="i-arrow img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ position: 'absolute', left: '50%', top: '100px', transform: 'translate(-50%, 0%)' }}>
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Content;
