export const options = {
    legend: { show: false },
    plotOptions: {
        heatmap: {
            radius: 2,
            enableShades: true,
            shadeIntensity: 0.5,
            colorScale: {
                min: 3,
                max: 5.5,
                ranges: [
                    {
                        from: 2.99,
                        to: 3.1,
                        color: '#200000',
                    },
                    {
                        from: 3.11,
                        to: 3.2,
                        color: '#400000',
                    },
                    {
                        from: 3.21,
                        to: 3.3,
                        color: '#600000',
                    },
                    {
                        from: 3.31,
                        to: 3.4,
                        color: '#800000',
                    },
                    {
                        from: 3.41,
                        to: 3.5,
                        color: '#a00000',
                    },
                    {
                        from: 3.51,
                        to: 3.6,
                        color: '#b00000',
                    },
                    {
                        from: 3.61,
                        to: 3.7,
                        color: '#c00000',
                    },
                    {
                        from: 3.71,
                        to: 3.8,
                        color: '#d00000',
                    },
                    {
                        from: 3.81,
                        to: 3.9,
                        color: '#e00000',
                    },
                    {
                        from: 3.91,
                        to: 4.0,
                        color: '#ff0000',
                    },
                    {
                        from: 4.01,
                        to: 4.1,
                        color: '#ff2222',
                    },
                    {
                        from: 4.11,
                        to: 4.2,
                        color: '#ff4444',
                    },
                    {
                        from: 4.21,
                        to: 4.3,
                        color: '#ff6666',
                    },
                    {
                        from: 4.31,
                        to: 4.4,
                        color: '#ff8888',
                    },
                    {
                        from: 4.41,
                        to: 4.5,
                        color: '#ffaaaa',
                    },
                    {
                        from: 4.51,
                        to: 4.6,
                        color: '#ffbbbb',
                    },
                    {
                        from: 4.61,
                        to: 4.7,
                        color: '#ffcccc',
                    },
                    {
                        from: 4.71,
                        to: 4.8,
                        color: '#ffdddd',
                    },
                    {
                        from: 4.81,
                        to: 4.9,
                        color: '#ffeeee',
                    },
                    {
                        from: 4.91,
                        to: 5.0,
                        color: '#ffffff',
                    },
                ],
            },
        },
    },
    dataLabels: {
        enabled: false,
        style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            colors: ['#000000'],
        },
    },
};

export const managementSeries = [
    {
        name: 'Tyrone',
        data: [
            { x: '2M053 - 006. Intercontinental ballistic missile wing', y: 4.06 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 3.72 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 3.97 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.23 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.33 },
        ],
    },
    {
        name: 'Mark',
        data: [
            { x: '2M053 - 006. Intercontinental ballistic missile wing', y: 4.21 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 4.32 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 4.22 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.5 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.63 },
        ],
    },
    {
        name: 'Laurie',
        data: [
            { x: '2M053 - 006. Intercontinental ballistic missile wing', y: 4.29 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 4.42 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 4.38 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.51 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.53 },
        ],
    },
];

export const leadershipSeries = [
    {
        name: '90 MMXS',
        data: [
            { x: '2M053 - Volume 1. Missile and Space Facilities Maintenance Orientation', y: 4.06 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 3.72 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 3.97 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.23 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.33 },
        ],
    },
    {
        name: '90 MXG”',
        data: [
            { x: '2M053 - Volume 1. Missile and Space Facilities Maintenance Orientation', y: 4.21 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 4.32 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 4.22 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.5 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.63 },
        ],
    },
    {
        name: '90 OG',
        data: [
            { x: '2M053 - Volume 1. Missile and Space Facilities Maintenance Orientation', y: 4.29 },
            { x: '2M053 - 016. Nuclear certified equipment', y: 4.42 },
            { x: 'CDL - 2.1. Driving Safely - Vehicle Inspection Overview', y: 4.38 },
            { x: 'CDL - 028. Radio frequency interference gasket inspections', y: 4.51 },
            { x: 'CDL - 2.4. Driving Safely - Seeing', y: 4.53 },
        ],
    },
];
