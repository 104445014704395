import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Components
import { Link, Redirect } from 'react-router-dom';

// Assets
import ArrowWhiteIcon from 'assets/Icons/arrowWhite.svg';

// Styles
import './LMS.scss';

// APIs
import userService from 'services/user.service';

const Program = () => {
    const [courses, setCourses] = useState([]);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(false);
        userService.getUserMe().then(response => {
            setCourses(response.courses_assigned);
            setMounted(true);
        });
    }, []);

    return (
        <div>
            {localStorage.getItem('isLogged') ? (
                mounted ? (
                    <div id="LMS" className="mt-5">
                        <h1>
                            <strong>Courses</strong>
                        </h1>
                        <p className="subtext-color mt-3">
                            {courses.length ? (
                                <>
                                    List of all <strong>courses</strong> that
                                    you're enrolled in.
                                </>
                            ) : (
                                <>No assigned course</>
                            )}
                        </p>
                        {/* Courses */}
                        {
                            <div id="courses" className="row">
                                {courses.map(course => (
                                    <div
                                        key={course.id}
                                        className="lms-cards d-flex p-2 mt-4"
                                    >
                                        {' '}
                                        {/* col-sm-12 col-md-4 col-lg-4 mt-4 */}
                                        <div className="card">
                                            <LazyLoadImage
                                                className="card-img-top"
                                                alt="Card image cap"
                                                effect="blur"
                                                src="https://i.ibb.co/2YSCWbT/2-M0-X3-IMG.webp"
                                            />
                                            <div className="card-body mt-2">
                                                <h4 className="card-title">
                                                    {course.name}
                                                </h4>
                                                <p className="subtext-color mt-3">
                                                    {course.description}
                                                </p>
                                                <Link
                                                    to={{
                                                        pathname: `/lms/${course.id}`,
                                                        state: {
                                                            courseId: `${course.id}`,
                                                            courseTitle: `${course.title}`,
                                                            courseDescription: `${course.description}`
                                                        }
                                                    }}
                                                    className="btn btn-primary w-100 mt-2"
                                                >
                                                    Start Course{' '}
                                                    <img
                                                        src={ArrowWhiteIcon}
                                                        alt="Arrow Icon"
                                                        className="i-arrow img-fluid"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                        {/* End of Courses */}
                    </div>
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '100px',
                            transform: 'translate(-50%, 0%)'
                        }}
                    >
                        <div className="spinner-border text-muted" />
                    </div>
                )
            ) : (
                <Redirect to="/login" />
            )}
            {localStorage.getItem('af_student') === '2M0X3' ? (
                <Redirect to="/home" />
            ) : null}
        </div>
    );
};

export default Program;
