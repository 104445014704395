import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';

// Components
import { GlobalContext } from 'stores/globalStore';

// Assets
import Logo from 'assets/learneckeLogo.png';

// Icons
import HomeIcon from 'assets/Icons/home.svg';
import CoursesIcon from 'assets/Icons/courses.svg';
import GradesIcon from 'assets/Icons/grades.svg';
import MyAccountIcon from 'assets/Icons/myaccount.svg';
import LogoutIcon from 'assets/Icons/logout.svg';

// Styles
import './StudentSidebar.scss';

const StudentSidebar = () => {
    const { store, dispatch } = useContext(GlobalContext);
    const { showSidebar } = store;

    const [fullName, setFullName] = useState('');
    const [overlayWidth, setOverlayWidth] = useState(-325);

    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        setFullName(localStorage.getItem('full_name'));
    }, []);

    useEffect(() => {
        if (showSidebar) {
            setOverlayWidth(0);
        } else {
            setOverlayWidth(-325);
        }
    }, [showSidebar]);

    const Logout = () => {
        localStorage.clear();
    };

    // Sidebar Toggler
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 1024) {
            setIsToggled(true);
        } else {
            setIsToggled(false);
        }
    }, []);
    
    const handleClickLink = () => {
        dispatch({
            type: 'updateSidebar',
            showSidebar: false,
        });
    }

    const closeOverlay = () => {
        dispatch({
            type: 'updateSidebar',
            showSidebar: false,
        });
    };

    return (
        <div id="SidebarWrapper">
            <div onClick={closeOverlay} className={showSidebar ? 'menuBack' : 'showDisable'} />
            <div id="student-sidebar" className="desktop-sidebar">
                <Link to="/home" className="pl-4 pr-4">
                    <img src={Logo} alt="Logo" className="img-fluid" />
                </Link>

                <div className="mt-5 pl-4 pr-4">
                    <button type="button" href="#" className="btn btn-secondary p-1-5 w-100 disabled" disabled>
                        Contact Instructor
                    </button>
                </div>

                <ul className="mt-5">
                    <Link className="sidebar-nav-link" to="/home">
                        <li className={pathname.includes('home') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={HomeIcon} alt="" className="img-fluid sidebar-icon" /> <span>Home</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    {localStorage.getItem('af_student') === 'CVOT' ? (
                        <Link className="sidebar-nav-link" to="/lms">
                            <li className={pathname.includes('lms') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Courses</span>
                                <div className="float-right">
                                    <span className="badge-secondary btn-blob">1</span>
                                </div>
                            </li>
                        </Link>
                    ) : null}
                    {localStorage.getItem('af_student') === '2M0X3' ? (
                        <Link className="sidebar-nav-link" to="/quizzes">
                            <li className={pathname.includes('quizzes') ? 'active' : ''}>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Quizzes</span>
                                <div className="float-right">
                                    <span className="badge-secondary btn-blob">1</span>
                                </div>
                            </li>
                        </Link>
                    ) : null}
                    <Link className="sidebar-nav-link" to="/grades">
                        <li className={pathname.includes('grades') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={GradesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Grades</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/myaccount">
                        <li className={pathname.includes('myaccount') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={MyAccountIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>My Account</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                </ul>

                <div className="sidebar-bottom">
                    <ul>
                        <div className="divider" />
                        <Link className="sidebar-nav-link" onClick={Logout} to="/login">
                            <li>
                                <img src={LogoutIcon} alt="" className="img-fluid" /> <span>Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>

            <div id="student-sidebar" style={{ marginLeft: `${overlayWidth}px` }} className="mobile-sidebar">
                <Link to="/home" className="pl-4 pr-4" onClick={handleClickLink}>
                    <img src={Logo} alt="Logo" className="img-fluid" />
                </Link>

                <div className="mt-5 pl-4 pr-4">
                    <button type="button" href="#" className="btn btn-secondary p-1-5 w-100 disabled" disabled>
                        Contact Instructor
                    </button>
                </div>

                <ul className="mt-5">
                    <Link className="sidebar-nav-link" to="/home" onClick={handleClickLink}>
                        <li className={pathname.includes('home') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={HomeIcon} alt="" className="img-fluid sidebar-icon" /> <span>Home</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    {localStorage.getItem('af_student') === 'CVOT' ? (
                        <Link className="sidebar-nav-link" to="/lms" onClick={handleClickLink}>
                            <li className={pathname.includes('lms') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Courses</span>
                                <div className="float-right">
                                    <span className="badge-secondary btn-blob">1</span>
                                </div>
                            </li>
                        </Link>
                    ) : null}
                    {localStorage.getItem('af_student') === '2M0X3' ? (
                        <Link className="sidebar-nav-link" to="/quizzes" onClick={handleClickLink}>
                            <li className={pathname.includes('quizzes') ? 'active' : ''}>
                                <img src={CoursesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Quizzes</span>
                                <div className="float-right">
                                    <span className="badge-secondary btn-blob">1</span>
                                </div>
                            </li>
                        </Link>
                    ) : null}
                    <Link className="sidebar-nav-link" to="/grades" onClick={handleClickLink}>
                        <li className={pathname.includes('grades') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={GradesIcon} alt="" className="img-fluid sidebar-icon" /> <span>Grades</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                    <div className="divider" />
                    <Link className="sidebar-nav-link" to="/myaccount" onClick={handleClickLink}>
                        <li className={pathname.includes('myaccount') ? 'active pl-4 d-flex justify-content-between' : 'pl-4'}>
                            <div>
                                <img src={MyAccountIcon} alt="" className="img-fluid sidebar-icon" />{' '}
                                <span>My Account</span>
                            </div>
                            <div className="active-mark" />
                        </li>
                    </Link>
                </ul>

                <div className="sidebar-bottom">
                    <ul>
                        <div className="divider" />
                        <Link className="sidebar-nav-link" onClick={Logout} to="/login">
                            <li>
                                <img src={LogoutIcon} alt="" className="img-fluid" /> <span>Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
            {/* End of Sidebar */}
        </div>
    );
};

export default StudentSidebar;
