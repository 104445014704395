import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const GENERATE_QUESTION_URL =
    process.env.REACT_APP_API_GENERATE_QUESTION_BASE_URL;

class UserService {
    // users APIs
    getAllUsers(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/users/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewUser(email, full_name, password) {
        const jsonData = {
            email,
            full_name,
            password
        };
        return axios
            .post(`${API_URL}api/v1/users/`, jsonData, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    getUserMe() {
        return axios
            .get(`${API_URL}api/v1/users/me`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateUserMe(full_name, email) {
        const data = {
            full_name,
            email
        };
        return axios
            .put(`${API_URL}api/v1/users/me`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    uploadAvatarMe(fileFormData) {
        return axios
            .post(`${API_URL}api/v1/users/me/upload_avatar`, fileFormData, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    getAvatarMe() {
        return axios
            .get(`${API_URL}api/v1/users/me/get_avatar`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewUserOpen(password, full_name, email) {
        const data = new FormData();
        data.set('password', password);
        data.set('full_name', full_name);
        data.set('email', email);

        return axios
            .put(`${API_URL}api/v1/users/open`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    getUserById(id) {
        return axios
            .get(`${API_URL}api/v1/users/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateUserById(
        id,
        email,
        is_active,
        is_superuser,
        full_name,
        lms_role,
        password,
        courses
    ) {
        const data = new FormData();
        data.set('email', email);
        data.set('is_active', is_active);
        data.set('is_superuser', is_superuser);
        data.set('full_name', full_name);
        data.set('lms_role', lms_role);
        data.set('password', password);
        data.set('courses', courses);
        return axios
            .put(`${API_URL}api/v1/users/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    // courses APIs
    getAllCourses(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/courses/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewCourse(title, description, lessons) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('lessons', lessons);
        return axios
            .post(`${API_URL}api/v1/courses/`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    getCourseById(id) {
        return axios
            .get(`${API_URL}api/v1/courses/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateCourseById(id, title, description, lessons) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('lessons', lessons);
        return axios
            .put(`${API_URL}api/v1/courses/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteCourseById(id) {
        return axios
            .delete(`${API_URL}api/v1/courses/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    // lessons APIs
    getAllLessons(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/lessons/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewLesson(title, description, image, articles, tags) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('image', image);
        data.set('articles', articles);
        data.set('tags', tags);
        return axios
            .post(`${API_URL}api/v1/lessons/`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    getLessonById(id) {
        return axios
            .get(`${API_URL}api/v1/lessons/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateLessonById(id, title, description, image, articles, tags) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('image', image);
        data.set('articles', articles);
        data.set('tags', tags);
        return axios
            .put(`${API_URL}api/v1/lessons/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteLessonById(id) {
        return axios
            .delete(`${API_URL}api/v1/lessons/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    // articles APIs
    getAllArticles(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/articles/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewArticles(owner_id, title, description, text, tags) {
        const data = new FormData();
        data.set('owner_id', owner_id);
        data.set('title', title);
        data.set('description', description);
        data.set('text', text);
        data.set('tags', tags);
        return axios
            .post(`${API_URL}api/v1/articles/`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    getArticlesByTags(tags, skip = 0, limit = 100) {
        return axios
            .post(
                `${API_URL}api/v1/articles/tags?skip=${skip}&limit=${limit}`,
                tags,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    getArticlesByIds(indices, skip = 0, limit = 100) {
        return axios
            .post(
                `${API_URL}api/v1/articles/index?skip=${skip}&limit=${limit}`,
                indices,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    getArticeById(id) {
        return axios
            .get(`${API_URL}api/v1/articles/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateArticleById(id, owner_id, title, description, text, tags) {
        const data = new FormData();
        data.set('owner_id', owner_id);
        data.set('title', title);
        data.set('description', description);
        data.set('text', text);
        data.set('tags', tags);
        return axios
            .put(`${API_URL}api/v1/articles/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteArticleById(id) {
        return axios
            .delete(`${API_URL}api/v1/articles/${id}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    // quizitems APIs
    getAllQuizitems(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/quizitems/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewQuizitem(
        type,
        question,
        answer,
        choices,
        tags,
        reference,
        points,
        owner_id,
        quiz_id
    ) {
        const data = new FormData();
        data.set('type', type);
        data.set('question', question);
        data.set('answer', answer);
        data.set('choices', choices);
        data.set('tags', tags);
        data.set('reference', reference);
        data.set('points', points);
        data.set('owner_id', owner_id);
        data.set('quiz_id', quiz_id);
        return axios
            .post(`${API_URL}api/v1/quizitems/`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    getQuizitemsById(id) {
        return axios
            .get(`${API_URL}api/v1/quizitems/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateQuizitemsById(
        id,
        type,
        question,
        answer,
        choices,
        tags,
        reference,
        points,
        owner_id,
        quiz_id
    ) {
        const data = new FormData();
        data.set('type', type);
        data.set('question', question);
        data.set('answer', answer);
        data.set('choices', choices);
        data.set('tags', tags);
        data.set('reference', reference);
        data.set('points', points);
        data.set('owner_id', owner_id);
        data.set('quiz_id', quiz_id);

        return axios
            .put(`${API_URL}api/v1/quizitems/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteQuizitemsById(id) {
        return axios
            .delete(`${API_URL}api/v1/quizitems/${id}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    // quizzes APIs
    getAllQuizzes(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/quizzes/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewQuizzes(title, description, tags, points, quizitems) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('tags', tags);
        data.set('points', points);
        data.set('quizitems', quizitems);
        return axios
            .post(`${API_URL}api/v1/quizzes/`, data, { headers: authHeader() })
            .then(response => response.data);
    }

    getQuizzesById(id) {
        return axios
            .get(`${API_URL}api/v1/quizzes/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    updateQuizzesById(id, title, description, tags, points, quizitems) {
        const data = new FormData();
        data.set('title', title);
        data.set('description', description);
        data.set('tags', tags);
        data.set('points', points);
        data.set('quizitems', quizitems);

        return axios
            .put(`${API_URL}api/v1/quizzes/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteQuizzesById(id) {
        return axios
            .delete(`${API_URL}api/v1/quizzes/${id}`, { headers: authHeader() })
            .then(response => response.data);
    }

    // quizresults APIs
    getAllQuizresults(skip = 0, limit = 100) {
        return axios
            .get(`${API_URL}api/v1/quizresults/?skip=${skip}&limit=${limit}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    createNewQuizresults(score, result, userId, lessonId) {
        const data = {
            score: score,
            student_id: userId,
            lesson_id: lessonId,
            result: result
        };
        return axios
            .post(`${API_URL}api/v1/quizresults/`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    getQuizresultsById(id) {
        return axios
            .get(`${API_URL}api/v1/quizresults/${id}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    updateQuizresultsById(id, timestamp, score, quiz_id, title) {
        const data = new FormData();
        data.set('timestamp', timestamp);
        data.set('score', score);
        data.set('quiz_id', quiz_id);
        data.set('title', title);

        return axios
            .put(`${API_URL}api/v1/quizresults/${id}`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    deleteQuizresultsById(id) {
        return axios
            .delete(`${API_URL}api/v1/quizresults/${id}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    // utils APIs
    testCelery(msg) {
        const data = new FormData();
        data.set('msg', msg);
        return axios
            .post(`${API_URL}api/v1/utils/test-celery`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    testEmail(email) {
        return axios
            .post(
                `${API_URL}api/v1/utils/test-celery/?email_to=${email}`,
                null,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    getGenerateQuestionOptions() {
        return axios
            .get(`${GENERATE_QUESTION_URL}api/v1/get_options`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    generateQuestions(text) {
        const requestText = `generate <multiplechoice> <question> <answer> <choices>: <text> ${text}`;

        const data = {
            data: requestText,
            model: 'v1_large'
        };
        return axios
            .post(`${GENERATE_QUESTION_URL}api/v1/generate_questions`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    getQuestions(id) {
        return axios
            .get(`${GENERATE_QUESTION_URL}api/v1/get_questions?user_id=${id}`, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    saveQuestions(questionData, userId) {
        questionData.user_id = userId;
        return axios
            .post(
                `${GENERATE_QUESTION_URL}api/v1/save_question`,
                questionData,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    deleteQuestion(questionId, userId) {
        const data = {
            user_id: userId
        };
        return axios
            .post(
                `${GENERATE_QUESTION_URL}api/v1/delete_question/${questionId}`,
                data,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    updateQuestion(questionId, data) {
        return axios
            .put(
                `${GENERATE_QUESTION_URL}api/v1/update_question/${questionId}`,
                data,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    removeLearnerFromCourse(learnerId, courseId) {
        return axios
            .put(
                `${API_URL}api/v1/users/${learnerId}/remove_course/${courseId}`,
                null,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    enrollLearersToCourse(learnerId, courseId) {
        return axios
            .put(
                `${API_URL}api/v1/users/${learnerId}/assign_course/${courseId}`,
                null,
                { headers: authHeader() }
            )
            .then(response => response.data);
    }

    enrollMultiLearnersToCourse(learnerIdList, courseId) {
        const data = {
            user_ids: learnerIdList,
            course_id: courseId
        };
        return axios
            .post(`${API_URL}api/v1/users/assign_course_multi/`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }

    updateLearnerAnalytics(
        sessionId,
        questionId,
        qSeqId,
        choiceId,
        time,
        point,
        studentId
    ) {
        const data = {
            session_id: sessionId,
            quizitem_id: questionId,
            quizitem_order_asked: qSeqId,
            choice_id: choiceId,
            time_spent: time,
            points: point,
            student_id: studentId
        };
        return axios
            .post(`${API_URL}api/v1/learning_analytics/`, data, {
                headers: authHeader()
            })
            .then(response => response.data);
    }
}

export default new UserService();
