import 'App.scss';

// Components
import React, { useReducer, useCallback, useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initialState, GlobalContext, reducer } from 'stores/globalStore';
import { GenerateQuestionModal, RegenerateQuestionModal } from 'components';
import userService from 'services/user.service';

// Routers
import Routes from 'Routes';

const browserHistory = createBrowserHistory();

function App() {
    const [store, dispatch] = useReducer(reducer, initialState);
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [show, setShow] = useState(false); // hide menu
    const [selectedText, setSelectedText] = useState('');
    const [generateModalShow, setGenerateModalShow] = useState(false);
    const [regenerateModalShow, setRegenerateModalShow] = useState(false);
    const [highlight, setHighlight] = useState('');
    const [questions, setQuestions] = useState({});
    const [allQuestionsNumber, setAllQuestionsNumber] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);

    const handleContextMenu = useCallback(
        (event) => {
            const role = localStorage.getItem('lms_role');
            const roleArray = ['superuser', 'system', 'instructor', 'admin'];
            if (roleArray.includes(role)) {
                const selected = window.getSelection().toString();
                event.preventDefault();
                if (localStorage.getItem('isLogged')) {
                    userService.getQuestions(localStorage.getItem('user_id')).then((res) => {
                        if (res === 0) {
                            setAllQuestionsNumber(0);
                        } else {
                            setAllQuestionsNumber(res.length);
                        }
                        setAnchorPoint({ x: event.pageX, y: event.pageY });
                        setSelectedText(selected);
                        setHighlight(selected);
                        setShow(true);
                    });
                }
            }
        },
        [setAnchorPoint]
    );

    const handleGenerate = () => {
        setIsGenerating(true);
        userService.generateQuestions(highlight).then((res) => {
            setIsGenerating(false);
            setGenerateModalShow(false);
            setQuestions(res[0]);
            setRegenerateModalShow(true);
        });
    };

    const handleRegenerate = () => {
        setIsGenerating(true);
        userService.generateQuestions(highlight).then((res) => {
            setIsGenerating(false);
            setQuestions(res[0]);
        });
    };

    const handleSaveQuestions = () => {
        userService.saveQuestions(questions, localStorage.getItem('user_id')).then((res) => {
            setRegenerateModalShow(false);
        });
    };

    const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

    useEffect(() => {
        document.addEventListener('click', handleClick);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    });

    useEffect(() => {
        userService.getUserMe()
        .then(response => {
            dispatch({
                type: 'updateUserInfo',
                userInfo: {
                    fullName: response.full_name,
                    avatar: response.image_url,
                }
            })
        })
    }, [])

    const handleClickContextMenu = () => {
        setGenerateModalShow(true);
    };

    const handleShowAllQuestions = () => {
        window.open('/allQuestions', '_blank');
    };

    const handleChangeHighlight = (e) => {
        setHighlight(e.target.value);
    };

    const handleChangeQuestions = (e) => {
        const temp = { ...questions };
        if (e.target.name === 'question') {
            temp.question = e.target.value;
        } else if (e.target.name === 'answer') {
            temp.answer = e.target.value;
        } else {
            temp.choices[e.target.id] = e.target.value;
        }
        setQuestions(temp);
    };

    const handleHideRegenerate = () => {
        setRegenerateModalShow(false);
        setIsGenerating(false);
    };

    const handleHideGenerate = () => {
        setGenerateModalShow(false);
        setIsGenerating(false);
    };

    return (
        <GlobalContext.Provider value={{ store, dispatch }}>
            {show ? (
                <div>
                    <ul
                        className="menu"
                        style={{
                            top: anchorPoint.y,
                            left: anchorPoint.x,
                        }}
                    >
                        <div className="contextMenu" onClick={handleClickContextMenu}>
                            {selectedText.length === 0 ? (
                                <li>Generate Questions</li>
                            ) : (
                                <li>
                                    Generate Questions for "
                                    {selectedText.length > 45 ? `${selectedText.slice(0, 45)}...` : selectedText}"
                                </li>
                            )}
                        </div>
                        <div className="contextMenu" onClick={handleShowAllQuestions}>
                            <li>
                                Show All Questions
                                {` (${allQuestionsNumber})`}
                            </li>
                        </div>
                    </ul>
                </div>
            ) : (
                <> </>
            )}

            <GenerateQuestionModal
                show={generateModalShow}
                onHide={() => handleHideGenerate()}
                handleGenerate={() => handleGenerate()}
                highlight={highlight}
                handleChangeHighlight={handleChangeHighlight}
                isGenerating={isGenerating}
            />
            <RegenerateQuestionModal
                show={regenerateModalShow}
                onHide={() => handleHideRegenerate()}
                handleRegenerate={() => handleRegenerate()}
                questions={questions}
                handleChangeQuestions={handleChangeQuestions}
                handleSaveQuestions={handleSaveQuestions}
                isGenerating={isGenerating}
            />
            <Router history={browserHistory}>
                <Routes />
            </Router>
        </GlobalContext.Provider>
    );
}

export default App;
