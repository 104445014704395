import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

// Components
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import * as ReactBootStrap from 'react-bootstrap';
import { GlobalContext } from 'stores/globalStore';

// Styles
import './Navigation.scss';

// Icons
import ArrowBlack from 'assets/Icons/arrowBlack.svg';

// Assets
// import Logo from 'assets/geLogo.png'
import Logo from 'assets/learneckeLogo.png';

// Utils
import { getAbbreviateName } from 'utils/getAbbreviateName';

const Navigation = () => {
    const { store, dispatch } = useContext(GlobalContext);
    const { userInfo } = store;

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const Logout = () => {
        localStorage.clear();
    };

    return (
        <div id="Navigation">
            <ReactBootStrap.Navbar collapseOnSelect expand="lg" bg="light" variant="light" fixed="top">
                <Container className="extended">
                    {' '}
                    {/* extended */}
                    <ReactBootStrap.Navbar.Brand href="#home">
                        <Link to="/">
                            <img id="geLogo" src={Logo} alt="Growth Engine AI Logo" className="img-fluid" />
                        </Link>
                    </ReactBootStrap.Navbar.Brand>
                    <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                        <ReactBootStrap.Nav className="mr-auto" />
                        <ReactBootStrap.Nav>
                            <ul className="navbar-nav">
                                {/* {
                                    localStorage.getItem("isLogged") ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to={'/home'}><strong>Educational Content</strong></Link>
                                        </li>
                                        : null
                                } */}
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to={'/'}><strong>Growth Engine</strong></Link>
                                </li> */}
                                <li className="nav-item">
                                    {localStorage.getItem('isLogged') === 'true' ? (
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle id="avatar_dropdown">
                                                {
                                                    userInfo?.avatar ? (
                                                        <img
                                                            src={userInfo.avatar}
                                                            alt="Upload Icon"
                                                            className="img-fluid avatar-icon"
                                                        />
                                                    ) : (
                                                        getAbbreviateName(userInfo?.fullName)
                                                    )
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem text>
                                                    <Link to="/" className="nav-link">
                                                        Website
                                                    </Link>
                                                </DropdownItem>
                                                {localStorage.getItem('lms_role') === 'admin' ? null : (
                                                    <DropdownItem text>
                                                        <Link to="/home" className="nav-link">
                                                            Dashboard
                                                        </Link>
                                                    </DropdownItem>
                                                )}
                                                {localStorage.getItem('lms_role') === 'admin' ||
                                                    localStorage.getItem('lms_role') === 'instructor' ? (
                                                    <DropdownItem text>
                                                        <Link to="/app" className="nav-link">
                                                            Admin Desk
                                                        </Link>
                                                    </DropdownItem>
                                                ) : null}
                                                <DropdownItem divider />
                                                <DropdownItem text>
                                                    <Link onClick={Logout} className="nav-link" to="/login">
                                                        Logout
                                                    </Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    ) : (
                                        <Link className="nav-link btn btn-secondary btn-rounded-sm" to="/login">
                                            <strong>
                                                Login <img src={ArrowBlack} alt="" className="i-arrow img-fluid" />
                                            </strong>
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        </ReactBootStrap.Nav>
                    </ReactBootStrap.Navbar.Collapse>
                </Container>
            </ReactBootStrap.Navbar>
        </div>
    );
};

export default Navigation;
