export const getAbbreviateName = (name) => {
  if(name) {
    const arrayName = name.split(' ');
    if(arrayName.length > 1) {
      return arrayName[0][0].toUpperCase() +  arrayName.at(-1)[0].toUpperCase();
    } else {
      return arrayName[0][0].toUpperCase()
    }
  } else {
    return '';
  }
}