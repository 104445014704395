import React from 'react';

// Components
import { Navigation, Footer, Hero } from 'components';

const Homepage = () => (
    <div>
        <Navigation />
        <Hero />
        <Footer />
    </div>
);

export default Homepage;
