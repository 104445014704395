import React from 'react';
import { Sidebar, Navbar } from 'components';
import { LmsWrapper } from 'hoc';

const Layout = props => {
  const { children } = props;
  return (
    <LmsWrapper>
      <Sidebar />
      <Navbar />
      {children}        
    </LmsWrapper>
  )
}

export default Layout;